export const ROUTES = [
    { path: '/home', title: 'Home', icon: 'dashboard', children: null },
    // { path: '/profile', title: 'Perfil', icon: 'person', children: null },
    { path: '/talleres', title: 'Talleres', icon: 'location_city', children: null },
    { path: '/noticias', title: 'Noticias', icon: 'add_alert', children: null },
    { path: '/preguntas', title: 'Preguntas', icon: 'help', children: null },
    { path: '/formatos', title: 'Formatos', icon: 'assignment', children: null },
    { path: '/partidos', title: 'Partidos', icon: 'contacts', children: null },
    { path: '/penitenciario', title: 'E. Penitenciarios', icon: 'store', children: null },
    { path: '/usuarios', title: 'Usuarios', icon: 'supervisor_account', children: null },
    // { path: '/homologacion', title: 'Homologacion', icon: 'supervisor_account', children: null },
    { path: '/homologacion', id: 'homologacion', title: 'Homologacion', icon: 'apps', children: [
      {path: 'departamentos', title: 'Departamentos', icon: 'D'},
      {path: 'municipios', title: 'Municipios', icon: 'M'},
    ]},
   /*{ path: 'table', title: 'Table List', icon: 'content_paste', children: null },
    { path: '#component', id: 'component', title: 'Component', icon: 'apps', children: [
        {path: 'components/price-table', title: 'Price Table', icon: 'PT'},
        {path: 'components/panels', title: 'Panels', icon: 'P'},
        {path: 'components/wizard', title: 'Wizard', icon: 'W'},
      ]},
    { path: 'notification', title: 'Notification', icon: 'notifications', children: null },
    { path: 'alert', title: 'Sweet Alert', icon: 'warning', children: null },
    { path: 'settings', title: 'Settings', icon: 'settings', children: null },*/
];
