import { Component, Inject, OnInit } from '@angular/core';
import {  FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { JobsService } from './jobs.service'

import Swal from 'sweetalert2/src/sweetalert2.js';
@Component({
  selector: 'app-jobs-edit',
  templateUrl: './jobs-edit.component.html',
  styleUrls: ['./jobs-edit.component.scss']
})
export class JobsEditComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  JobsNoticiaForm: FormGroup;
  JobsPreguntasForm: FormGroup;
  loading = false
  submitted = false;
  
  constructor(
    private JobsService: JobsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<JobsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: any  
  ) { }
  
  async ngOnInit() {
    this.loading = true;
    this.JobsService.getList().subscribe((res: any) =>{
      res.data.forEach(job => {
        if(job.jobID=='NoticiasJob'){
          let dias = 0;
          let horas = 0;
          let minutos = 0; 
          let segundos = 0; 
          let periodicidad=job.periodicidad;
          while (periodicidad != 0) {
            if(periodicidad >= 86400){
              dias += 1;
              periodicidad -= 86400;
            } else
            if(periodicidad >= 3600){
              horas += 1;
              periodicidad -= 3600;
            } else
            if(periodicidad >= 60){
              minutos += 1;
              periodicidad -= 60;
            } else{
              segundos = periodicidad ;
              periodicidad = 0;

            }
          }
          this.JobsNoticiaForm = this.formBuilder.group({
            dias: [dias, [Validators.required]],
            horas: [horas, [Validators.required]],
            minutos: [minutos, [Validators.required]],
            segundos: [segundos, [Validators.required]],
          });
        }
        if(job.jobID=='PreguntasJob'){
          let dias = 0;
          let horas = 0;
          let minutos = 0; 
          let segundos = 0; 
          let periodicidad=job.periodicidad;
          while (periodicidad != 0) {
            if(periodicidad >= 86400){
              dias += 1;
              periodicidad -= 86400;
            } else
            if(periodicidad >= 3600){
              horas += 1;
              periodicidad -= 3600;
            } else
            if(periodicidad >= 60){
              minutos += 1;
              periodicidad -= 60;
            } else{
              segundos = periodicidad ;
              periodicidad = 0;
            }
          }
          this.JobsPreguntasForm = this.formBuilder.group({
            dias: [dias, [Validators.required]],
            horas: [horas, [Validators.required]],
            minutos: [minutos, [Validators.required]],
            segundos: [segundos, [Validators.required]],
          });
        }
        this.loading = false;
      });
    })
  }

  get diasNoticias(){
    return this.JobsNoticiaForm.controls.dias;
  }
  get horasNoticias(){
    return this.JobsNoticiaForm.controls.horas;
  }
  get minutosNoticias(){
    return this.JobsNoticiaForm.controls.minutos;
  }
  get segundosNoticias(){
    return this.JobsNoticiaForm.controls.segundos;
  }
  get diasPreguntas(){
    return this.JobsPreguntasForm.controls.dias;
  }
  get horasPreguntas(){
    return this.JobsPreguntasForm.controls.horas;
  }
  get minutosPreguntas(){
    return this.JobsPreguntasForm.controls.minutos;
  }
  get segundosPreguntas(){
    return this.JobsPreguntasForm.controls.segundos;
  }

  async submitFormNoticias () {
    this.loading = true;
    this.submitted = true;
    const data = {
      JobID: 'NoticiasJob',
      Periodicidad: this.segundosNoticias.value +this.minutosNoticias.value * 60 + this.horasNoticias.value * 3600 + this.diasNoticias.value * 86400 
    } 
    this.JobsService.putJobs(data,'NoticiasJob').subscribe(res=> {
      Swal.fire({
        title: 'Actualizado correctamente!',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.loading = false
      this.dialogRef.close();
    }, error => {
      console.log(error)
      Swal.fire({
        title: 'Error al actualizar!',
        text: error.error.message,
        icon: 'error',
        confirmButtonColor: 'error'
      });
      this.loading = false
    });
  }
  
  async submitFormPreguntas () {
    this.loading = true;
    this.submitted = true;
    // const data = new FormData();
    // data.append('JobID','PreguntasJob');
    // data.append('Periodicidad',this.segundosPreguntas.value +this.minutosPreguntas.value * 60 + this.horasPreguntas.value * 3600 + this.diasPreguntas.value * 86400 );
    const data = {
      JobID: 'PreguntasJob',
      Periodicidad: this.segundosPreguntas.value +this.minutosPreguntas.value * 60 + this.horasPreguntas.value * 3600 + this.diasPreguntas.value * 86400 
    } 
    this.JobsService.putJobs(data,'PreguntasJob').subscribe(res=> {
      Swal.fire({
        title: 'Actualizado correctamente!',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.loading = false
    }, error => {
      Swal.fire({
        title: 'Error al actualizar!',
        text: error.error.message,
        icon: 'error',
        confirmButtonColor: 'error'
      });
      this.loading = false
    });
  }
  
  cerrar(){
    this.dialogRef.close();
  }
}
