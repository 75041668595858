import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../services/httpRequest.service';
@Injectable({
  providedIn: 'root'
})
export class FormatosService {

  constructor(
    private http: HttpRequestService
  ) { }


  getList() {
    return this.http.getEntries('Formatos');
  }
  getFormato(id) {
    return this.http.getEntry('Formatos',id);
  }
  getPregunta(id) {
    return this.http.getEntry('Formatos',id);
  }
  setPregunta(model) {
    return this.http.createEntry('Formatos',model);
  }
  putPregunta(model,id) {
    return this.http.updateEntry('Formatos',id,model);
  }
  //CARGUES
  setCargue(model) {
    return this.http.createEntry('Cargues',model);
  }
  getCarguesByFormato(idFormato,pageNumber=1) {
    return this.http.getEntries(`Cargues?PageNumber=${pageNumber}&PageSize=10&FormatoID=${idFormato}`);
  }
  getCargue(idCargue) {
    return this.http.getEntry(`Cargues`, idCargue);
  }
  setPublicar(cargueID) {
    return this.http.accionPOST(`Cargues/publicar?cargueID=${cargueID}`);
  }
  deleteCargue(id) {
    return this.http.deleteEntry('Cargues',id);
  }
  procesarCargue(id){
    return this.http.accionPOST(`Cargues/${id}/Procesar`)
  }
}
