import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
import {  HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class NoticiasService {

  constructor(
    private http: HttpRequestService
  ) { }


  getList(pageNumber=1) {
    return this.http.getEntries(`Noticias?PageNumber=${pageNumber}&PageSize=10`);
  }
  getNoticia(id) {
    return this.http.getEntry('Noticias',id);
  }
  setNoticia(model) {
    return this.http.uploadAndData2('Noticias',model);
  }
  
  putNoticia(model,id) {
    return this.http.uploadAndUpdateData('Noticias',id,model);
  }
  deleteNoticia(id) {
    return this.http.deleteEntry('Noticias',id);
  }
  sincronizar(){
    return this.http.accionPOST('Noticias/sincronizar');
  }
  
}
