import { MinistroEditComponent } from './../../dashboard/ministro-edit/ministroEdit.component';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { GeneralesState } from '../../state/generales.state';
import { MatDialog } from '@angular/material';
import { JobsEditComponent } from 'app/dashboard/jobs-edit/jobs-edit.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  title: string;
  user: string;
  @Select(GeneralesState.getGenerales) generales$: Observable<any>;
  constructor(
    private router: Router,
    private store: Store,
    private authServe: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.generales$.subscribe(data => {

      this.title = data.find(e => e.name == 'titulo') ? data.find(e => e.name == 'titulo').valor : '';
    });
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log(this.user)
  }

  menuClick() {
    // document.getElementById('main-panel').style.marginRight = '260px';
  }

  async logout() {
    await this.authServe.logout();
    this.router.navigate(['/auth/login']);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(JobsEditComponent, {
      width: '950px',
      height:'900px',
      data: null
    });
  }

  openDialogMinistro(): void {
    const dialogRef = this.dialog.open(MinistroEditComponent, {
      width: '950px',
      data: null
    });
  }
}
