import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
@Injectable({
  providedIn: 'root'
})
export class FormatosService {

  constructor(
    private http: HttpRequestService
  ) { }


  getList() {
    return this.http.getEntries('Formatos');
  }
  getFormato(id) {
    return this.http.getEntry('Formatos',id);
  }
  putFormato(model,id) {
    return this.http.updateEntry('Formatos',id,model);
  }
  //CARGUES
  uploadCargue(model) {
    return this.http.uploadAndData(`Cargues/Upload`,model);
  }
  analizarCargue(id) {
    return this.http.accionPOST(`Cargues/${id}/Analizar`);
  }
  procesarCargue(id) {
    return this.http.accionPOST(`Cargues/${id}/Procesar`);
  }
  
  
  setCargue(model) {
    return this.http.createEntry('Cargues',model);
  }
  getCarguesByFormato(idFormato, page = 1) {
    return this.http.getEntries(`Cargues?FormatoID=${idFormato}&PageNumber=${page}&PageSize=5`);
  }
  getCargue(idCargue) {
    return this.http.getEntry(`Cargues`, idCargue);
  }
  setPublicar(cargueID) {
    return this.http.accionPOST(`Cargues/publicar?cargueID=${cargueID}`);
  }
  deleteCargue(id) {
    return this.http.deleteEntry('Cargues',id);
  }
  carguesImagenes(data){
    return this.http.uploadAndData(`CarguesImagenes`,data)
  }
}
