import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { PartidosService } from '../partidos.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-partido-add',
  templateUrl: './partido-add.component.html',
  styleUrls: ['./partido-add.component.scss']
})
export class PartidoAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');

  PartidoForm = new FormGroup({
    Nombre: new FormControl('', [Validators.required]),
    Logo: new FormControl('', [Validators.required]),
  });
  submitted = false;
  error = {
    status: false,
    msg: ''
  };
  loading = false;
  edit = false;
  constructor(
    private PartidosServices: PartidosService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PartidoAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  async ngOnInit() {

    if (this.data.PartidoID != 0) {
      await this.PartidosServices.getPartido(this.data.PartidoID).subscribe((res: any) => {

        this.PartidoForm.setValue({
          Nombre: res.data.nombre,
          Logo: res.data.logo,
        });
        this.PartidoForm.addControl('LogoUpdate', new FormControl(''))
        this.PartidoForm.addControl('ID', new FormControl(res.data.id))
      });
      this.edit = true;
    }

    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/#/talleres';
  }

  setImagen(e) {
    console.log(e)
    if (this.edit) {
      this.PartidoForm.controls.LogoUpdate.setValue(e[0]);
    } else {
      this.PartidoForm.controls.Logo.setValue(e[0]);
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.PartidoForm.controls;
  }

  get nombre() {
    return this.PartidoForm.controls.Nombre;
  }
  get logo() {
    return this.PartidoForm.controls.Logo;
  }
  get LogoUpdate() {
    return this.PartidoForm.controls.LogoUpdate;
  }
  get ID() {
    return this.PartidoForm.controls.ID;
  }
  

  async submitForm() {
    const formData = new FormData();
    if (this.edit) {
      formData.append('ID', this.ID.value);
      formData.append('LogoUpdate', this.LogoUpdate.value);
    }
    formData.append('Nombre', this.nombre.value);
    formData.append('Logo', this.logo.value);
    const service = !this.edit ?
      this.PartidosServices.setPartido(formData) :
      this.PartidosServices.putPartido(formData, this.ID.value);

    await service.subscribe((res: any) => {
      Swal.fire({
        title: (res)?res.message:'Registro exitoso',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.dialogRef.close();
    },
      (error: any) => {
        Swal.fire({
          title: error.message,
          text: '',
          icon: 'error',
          confirmButtonColor: 'error',
          timer: 1000
        });
      })
  }

  cerrar(){
    this.dialogRef.close();
  }

}
