import { Component, Inject, OnInit } from '@angular/core';
import {  FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MinistroService } from './ministroEditservice'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'environments/environment';


import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-ministro-edit',
  templateUrl: './ministroEdit.component.html',
  styleUrls: ['./ministroEdit.component.scss']
})
export class MinistroEditComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  MinistroForm: FormGroup;
  loading = false
  submitted = false;
  public Editor = ClassicEditor;
  srcResult:string | ArrayBuffer="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNL_ZnOTpXSvhf1UaK7beHey2BX42U6solRA&usqp=CAU"
  datosMinistro:any;
  dataEditor="";
  fileSelected:any
  SERVER:string=environment.SERVER;

  constructor(
    private mService: MinistroService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MinistroEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: any  
  ) { }
  
  async ngOnInit() {
    this.loading = true;
    this.MinistroForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      perfil: ['', [Validators.required]],
      funciones: ['', [Validators.required]],
      objetivo: ['', [Validators.required]],
      contactos: ['', [Validators.required]],
      codigo: ['', [Validators.required]],
      nombreDespacho: ['', [Validators.required]],
      foto:new FormControl('', [Validators.required]),
    });
    

    this.mService.getMinistro().subscribe((d:any)=>{
      if(d){
        
        this.datosMinistro=d.data
        console.log(this.datosMinistro)
        this.MinistroForm.setValue({
          nombre:this.datosMinistro.nombre ,
          perfil:this.datosMinistro.perfil,
          funciones:this.datosMinistro.funciones ,
          objetivo:this.datosMinistro.objetivo,
          contactos: this.datosMinistro.contactos,
          codigo:this.datosMinistro.codigo ,
          nombreDespacho:this.datosMinistro.nombreDespacho  ,
          foto:this.SERVER.slice(0,this.SERVER.length-1)+this.datosMinistro.foto,
          
        })
        this.srcResult=this.SERVER+this.datosMinistro.foto,
      
        this.dataEditor=this.datosMinistro.contactos
        this.MinistroForm.addControl('FotoUpdate', new FormControl(''))
      }
      
    })
    
    //IMG MINISTRO https://api.minjusticia.ml/Resources/estructura/165dc7ec-cdd8-4013-9c4b-f60f2d74e443.png
  

    
  
  }

  setImagen(event) {
    this.MinistroForm.controls.FotoUpdate.setValue(event.target.files[0]);
    //console.log(event[0])
    
   
      const element = event.target.files[0];
      console.log(element)
      const reader = new FileReader();
      reader.readAsDataURL(element);
    
    
      reader.onload = () => {
        this.srcResult=reader.result;
      };
      
   
      
    
  }

  get nombre(){
    return this. MinistroForm.controls.nombre;
  }
  get perfil(){
    return this. MinistroForm.controls.perfil;
  }
  get funciones(){
    return this. MinistroForm.controls.funciones;
  }
  get objetivo(){
    return this. MinistroForm.controls.objetivo;
  }
  get contactos(){
    return this. MinistroForm.controls.contactos;
  }
  get codigo(){
    return this. MinistroForm.controls.codigo;
  }
  get nombreDespacho(){
    return this. MinistroForm.controls.nombreDespacho;
  }
 

  async submitForm () {
    this.loading = true;
    this.submitted = true;
    if(!this.MinistroForm.valid){
      Swal.fire({
        title: "Todos los campos son obligatorios",
        text: '',
        icon: 'error',
        confirmButtonColor: 'success'
      });

    }else{
      const formData = new FormData();
      formData.append("id",this.datosMinistro.id);
      formData.append("nombre",this.nombre.value);
      formData.append("perfil",this.perfil.value)
      formData.append("funciones",this.funciones.value)
      formData.append("objetivo",this.objetivo.value)
      formData.append("contactos",this.contactos.value)
      formData.append("codigo",this.datosMinistro.codigo)
      formData.append("nombreDespacho",this.nombreDespacho.value)
      formData.append("foto",this.datosMinistro.foto)
      formData.append("fotoUpdate",this.MinistroForm.controls.FotoUpdate.value)
      /*console.log("FOTO")
      console.log(this.MinistroForm.controls.FotoUpdate.value)*/



      
      /*console.log(formData.get("id"));
      console.log(formData.get("nombre"));
      console.log(formData.get("perfil"));
      console.log(formData.get("funciones"));
      console.log(formData.get("objetivo"));
      console.log(formData.get("contactos"));
      console.log(formData.get("codigo"));
      console.log(formData.get("foto"));
      console.log(formData.get("fotoUpdate"));*/

      this.mService.putMinistro(formData,this.datosMinistro.id).subscribe(res=>{
       
          Swal.fire({
            title: "Registro actualizado correctamente",
            text: '',
            icon: 'success',
            confirmButtonColor: 'success'
          });

       
      },
      err=>{
        Swal.fire({
          title: "Error al actualizar",
          text: '',
          icon: 'error',
          confirmButtonColor: 'success'
        });

      })

      
  
  
    }
    
  }
  preview(e,files) {
  
    this.fileSelected =files[0];

    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
   
      return;
    }
 
    var reader = new FileReader();
    

    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      
      this.srcResult = reader.result; 
    }
  }

  

  
  cerrar(){
    this.dialogRef.close();
  }
}
