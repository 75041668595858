import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
import {  HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TalleresService {

  constructor(
    private http: HttpRequestService
  ) { }


  /**
   * DIVIPOLA
   */
  getDepartamentos() {
    return this.http.getEntries('Departamentos');
  }
  getMunicipiosByDepartamento(id) {
    return this.http.getEntries(`Departamentos/${id}/municipios`);
  }

  getMunicipios() {
    return this.http.getEntries(`Municipios`);
  }

  getDepartemento(id) {
    return this.http.getEntry(`Departamentos`,id);
  }
  getMunipio(id) {
    return this.http.getEntry(`Municipios`,id);
  }
  publicarTaller(data){
    return this.http.createEntry(`Talleres/publicar`,data);
  }

  /**
   * TALLERES
   */
  getTalleres(PageNumber,PageSize, departamento, municipio) {
    let url = `Talleres?PageNumber=${PageNumber}&PageSize=${PageSize}`;
    if(departamento!=null) url += `&departamentoID=${departamento.value.departamentoID}`;
    if(municipio!=null) url += `&municipioID=${municipio.value.municipioID}`;
    return this.http.getEntries(url)
  }


}
