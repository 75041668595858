import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
import {  HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PenitenciarioService {

  constructor(
    private http: HttpRequestService
  ) { }


  getDepartamentos() {
    return this.http.getEntries('Departamentos');
  }
  getMunicipiosByDepartamento(id) {
    return this.http.getEntries(`Departamentos/${id}/municipios`);
  }

  getMunicipios() {
    return this.http.getEntries(`Municipios`);
  }

  getDepartemento(id) {

    return this.http.getEntry(`Departamentos`,id);
  }
  getMunipio(id) {
    return this.http.getEntry(`Municipios`,id);
  } 

  

  getList(pageNumber=1, filter) {
    let url = `EstablecimientosPenitenciarios?PageNumber=${pageNumber}&PageSize=10`;
    url = filter !== '' ? `${url}&Search=${filter}` : url;
    return this.http.getEntries(url);
  }

 
  getEstablecimiento(id) {
    return this.http.getEntry('EstablecimientosPenitenciarios',id);
  }
  setEstablecimiento(model) {
    return this.http.createEntry('EstablecimientosPenitenciarios',model);
  }
  putEstablecimiento(model,id) {
    return this.http.updateEntry('EstablecimientosPenitenciarios',id,model);
  }
  deleteEstablecimiento(id){
    return this.http.deleteEntry('EstablecimientosPenitenciarios',id);
  }
 

}
