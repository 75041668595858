import { Component, Inject, OnInit } from '@angular/core';
import {  FormArray, FormBuilder, FormControl, FormGroup, Validators, } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormatosService } from '../formatos.service'
import * as moment from 'moment';
import { ProcesoCargueComponent } from '../proceso-cargue/proceso-cargue.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-formatos-add',
  templateUrl: './formatos-add.component.html',
  styleUrls: ['./formatos-add.component.scss']
})
export class FormatosAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  formatos: any[] = [];
  CargueForm: FormGroup;
  ImagesForm: FormGroup;
  loading = false
  loadingDocument = false
  submitted = false;
  

  maxDate = new Date();

  constructor(
    private FormatosService: FormatosService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FormatosAddComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: any,
    public dialog: MatDialog
  ) { }

  async ngOnInit() {
    await this.FormatosService.getList().subscribe(data => {
      if (data) {
        this.formatos = data;
      }
    });
     const timeElapsed = Date.now();
     const today = new Date(timeElapsed);
     console.log(moment(today).format('YYYY-MM-DDTHH:MM:SS').split('T')[0] + 'T00:00:00')

    const formatoId = this.dataModal.id!=0 ? this.dataModal.id : '' ;
    this.CargueForm = this.formBuilder.group({
      FormatoId: [formatoId, [Validators.required]],
      FechaCorte: [moment(today).format('YYYY-MM-DDTHH:MM:SS').split('T')[0] + 'T00:00:00', [Validators.required]],
      Archivo: ['', [Validators.required]],
    });
    this.CargueForm.addControl('Archivo', new FormControl(''))

    if(formatoId == 'PPD_FT33'){
      this.ImagesForm = this.formBuilder.group({
        CampoImagen: ['FOTO', [Validators.required]],
        CampoFiltro: ['CEDULA', [Validators.required]],
        CargueId: ['', [Validators.required]],
        Formato: [formatoId, [Validators.required]],
        Archivo: [this.formBuilder.array([])]
      });
    }
  }

  get FormatoId() {
    return this.CargueForm.controls.FormatoId;
  }
  get FechaCorte() {
    return this.CargueForm.controls.FechaCorte;
  }
  get Archivo() {
    return this.CargueForm.controls.Archivo;
  }
  get ArchivoImagenes() {
    try {
      return this.ImagesForm.get('Archivo') as FormArray;
    } catch (error) {
      return null
    }
 }

  setDocument(e) {
    this.CargueForm.controls.Archivo.setValue(e);
    this.loadingDocument = false;
  }
  setImagen(e) {
    const imagenes = this.formBuilder.array([])
    e.forEach(image => {
      imagenes.push(this.formBuilder.control(image));
    });
    this.ImagesForm.controls.Archivo.setValue(imagenes);
  }

  async submitForm () {
    console.log(this.FormatoId.value)
    console.log(this.FechaCorte.value)
    console.log(this.Archivo.value)
    console.log(this.ArchivoImagenes)
    if(this.FormatoId.value == ''){
      Swal.fire({
        title: 'Debe elejir un formato',
        text: '',
        timer: 2000,
        icon: 'error',
      })
      return;
    }
    if(this.FechaCorte.value == ''){
      Swal.fire({
        title: 'Debe elejir una fecha de corte',
        text: '',
        timer: 2000,
        icon: 'error',
      })
      return;
    }
    if(this.Archivo.value == ''){
      Swal.fire({
        title: 'Debe cargar un archivo',
        text: '',
        timer: 2000,
        icon: 'error',
      })
      return;
    }
    if(this.FormatoId.value == 'PPD_FT33' && !this.ArchivoImagenes){
      Swal.fire({
        title: 'Debe cargar las imagenes',
        text: '',
        timer: 2000,
        icon: 'error',
      })
      return;
    }

    this.loading = true
    this.submitted = true
    const formData = new FormData();
    
    const CargueID = this.dataModal.cargueID !== undefined ? this.dataModal.cargueID : 0;
    const Adjuntar = this.dataModal.cargueID !== undefined;

    formData.append('FormatoId', this.FormatoId.value);
    formData.append('Archivo', this.Archivo.value);
    formData.append('CargueID', CargueID);
    formData.append('Adjuntar', Adjuntar ? 'true': 'false');

    console.log(this.FechaCorte.value)
    formData.append('FechaCorte', moment(this.FechaCorte.value).format('YYYY-MM-DDTHH:MM:SS'));
    const imagenes = this.FormatoId.value == 'PPD_FT33' ? this.ArchivoImagenes: null;
    const dialogRef = this.dialog.open(ProcesoCargueComponent, {
      width: '1050px',
      disableClose: true,
      data: {
        formData,
        imagenes
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loading = false;
      if(result == 'Close'){
        this.dialog.closeAll();
      }else{
        this.dialogRef.close();
      }
    });
  }

  documentoStart(e){
    console.log(e)
    this.loadingDocument = true;
  }

  cerrar(){
    this.dialogRef.close();
  }
}
