import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TalleresService } from '../talleres.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-talleres-add',
  templateUrl: './talleres-add.component.html',
  styleUrls: ['./talleres-add.component.scss']
})
export class TalleresAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');

  departamento = {
    nombre: ''
  };
  municipio = {
    nombre: ''
  };
  minDate = new Date();

  TallerForm = new FormGroup({
    titulo: new FormControl('', [Validators.required]),
    fechaPresentacion: new FormControl(new Date(), [Validators.required]),
    departamentoID: new FormControl('', [Validators.required]),
    municipioID: new FormControl('', [Validators.required]),
  });
  submitted = false;
  error = {
    status: false,
    msg: ''
  };
  loading = false;
  edit = false;
  constructor(
    private TalleresService: TalleresService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TalleresAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  async ngOnInit() {
    this.TallerForm.controls.departamentoID.setValue(this.data.departamento.departamentoID)
    this.TallerForm.controls.municipioID.setValue(this.data.municipio.municipioID)
    this.departamento = this.data.departamento
    this.municipio = this.data.municipio
    this.minDate.setDate(this.minDate.getDate()+1);
  }

  get titulo() {
    return this.TallerForm.controls.titulo;
  }

  get fechaPresentacion() {
    return this.TallerForm.controls.fechaPresentacion;
  }

  get departamentoID() {
    return this.TallerForm.controls.departamentoID;
  }

  get municipioID() {
    return this.TallerForm.controls.municipioID;
  }

  consultarDM(){
    this.TalleresService.getDepartemento(this.data.departamentoID).subscribe((res: any) => {
      this.departamento = res.data
    })
    this.TalleresService.getMunipio(this.data.municipioID).subscribe((res: any) => this.municipio = res.data)
  }

  submitForm(){
    this.loading = true;
    this.TalleresService.publicarTaller({
      nombre: this.titulo.value,
      fechaPresentacion: this.fechaPresentacion.value,
      departamentoID: this.departamentoID.value,
      municipioID: this.municipioID.value
    }).subscribe((res: any) => {
      if(res.succeeded){
        Swal.fire({
          title: 'Taller publicado',
          text: '',
          icon: 'success',
          confirmButtonColor: 'success',
          timer: 3000
        });
      }else{
        Swal.fire({
          title: res.message,
          text: '',
          icon: 'error',
          confirmButtonColor: 'error',
        });
      }
      this.loading = true;
      this.dialogRef.close();
    },
    (error: any) => {
      Swal.fire({
        title: error.error.message,
        text: '',
        icon: 'error',
        confirmButtonColor: 'error',
      });
      this.loading = true;
      })
  }

  cerrar(){
    this.dialogRef.close();
  }
}
