import { Injectable } from '@angular/core';
import { HttpRequestService } from 'app/services/httpRequest.service';
@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
    private http: HttpRequestService
  ) { }
  getList() {
    return this.http.getEntries('Jobs');
  }
  putJobs(model,id) {
    return this.http.accionPUT(`Jobs/${id}`,model);
  }
  
}
