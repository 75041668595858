import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormatosService } from './formatos.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../state/generales.actions';
import { FormatosAddComponent } from './formatos-add/formatos-add.component';
import { MatDialog } from '@angular/material';
import { FormatosCarguesComponent } from './formatos-cargues/formatos-cargues.component';
import { FormatosEditComponent } from './formatos-edit/formatos-edit.component';
@Component({
  selector: 'app-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: ['./formatos.component.css']
})
export class FormatosComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  listaFormatos: any[] = [];
  loadingTable: boolean = false;
  constructor(
    public router: Router,
    private store: Store,
    private FormatosService: FormatosService,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Formatos' }));
  }

  async ngOnInit() {
    this.getFormatos()
  }

  openDialog(id = 0): void {
    const dialogRef = this.dialog.open(FormatosAddComponent, {
      width: '1450px',
      data: {id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getFormatos();
    });
  }

  async getFormatos() {
    this.loadingTable = true;
    await this.FormatosService.getList().subscribe((data: any) => {
      if (data) {
        this.listaFormatos = data.sort((a , b)=> parseInt(a.nombre.slice(0,2)) - parseInt(b.nombre.slice(0,2)));
      }
      this.loadingTable = false;
    });
  }

}
