import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NoticiasService } from './noticias.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../state/generales.actions';
import { MatDialog } from '@angular/material';
import { NoticiaAddComponent } from "./noticia-add/noticia-add.component";
import { environment } from 'environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  lista: any[] = [];
  loading = false;
  api: string
  pagination = {
    pageNumber: 1,
    totalPages: 0,
  }

  constructor(
    public router: Router,
    private store: Store,
    private NoticiasService: NoticiasService,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Noticias' }));
  }

  async ngOnInit() {
    this.api = environment.API;
    await this.getNoticias();
  }

  openDialog(NoticiaID = 0): void {
    const dialogRef = this.dialog.open(NoticiaAddComponent, {
      width: '750px',
      data: {NoticiaID:NoticiaID}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getNoticias(this.pagination.pageNumber);
    });
  }

  async deleteNoticia(NoticiaID){
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea eliminar esta noticia ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
    .then(async (willDelete) => {
      if (willDelete.value) {
        await this.NoticiasService.deleteNoticia(NoticiaID).subscribe((res: any) => {
          Swal.fire({
            title:'Eliminado correctamente',
            text: '',
            icon: 'success',
          });
          this.getNoticias(this.pagination.pageNumber);
        },(error) => {
            Swal.fire({
              title: 'Error al eliminar!',
              text: error.error.message,
              icon: 'error',
              confirmButtonColor: 'success'
            });
        });
      } 
    });
  }
    
  async getNoticias (page = 1) {
    await this.NoticiasService.getList(page).subscribe((res: any) => {
      if(res.data.length != 0) {
        this.lista = res.data;
        this.pagination.pageNumber = res.pageNumber;
        this.pagination.totalPages = res.totalPages;
          this.lista.map(item => {
            if(item.entradilla && item.entradilla.length>20){
              item.entradillaCompacta = `${item.entradilla.slice(0,100)}...`
            }else{
              item.entradillaCompacta = item.entradilla;
            }
            return item;
          })
      }else{
        this.lista = [];
      }
    });
  }
  async sincronizar () {
    this.loading = true;
    await this.NoticiasService.sincronizar().subscribe((res: any) => {
      this.getNoticias(this.pagination.pageNumber);
      Swal.fire({
        title: (res)?res.message : 'Sincronizacion completada',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.loading = false;
    }, error => {
      this.loading = false;
      Swal.fire({
        title: (error)?error.error.Message : 'Error al sincronizar',
        text: '',
        icon: 'error',
        confirmButtonColor: 'error'
      });
    });
  }

}
