import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormatosService } from '../formatos.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-proceso-cargue',
  templateUrl: './proceso-cargue.component.html',
  styleUrls: ['./proceso-cargue.component.css']
})
export class ProcesoCargueComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  ENPOINT = '';
  loading = false

  color1 = '#c7c7c7';
  color2 = '#3F51B5';
  color3 = '#4CAF50';
  color4 = '#CB171A';

  linkHomologacion = '';
  cargueId = '';
  puedePublicar = false;

  proccess: {
    index: number,
    title: string,
    icon: string,
    color: string,
    status: string,
    error: string,
    logs: string
  }[];

  progress = 0;
  progress2 = 0;
  columnas = 3;

  formData = new FormData();
  imagenes: FormArray;
  
  constructor(
    private FormatosService: FormatosService,
    public dialogRef: MatDialogRef<ProcesoCargueComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: any,
  ) { }

  ngOnInit() {
    this.proccess = [
      {
        index: 0,
        title: 'Cargar archivo',
        icon: 'cloud_upload',
        color: this.color1,
        status: 'A',
        error: '',
        logs: ''
      },
      {
        index: 1,
        title: 'Analizar archivo',
        icon: 'plagiarism',
        color: this.color1,
        status: 'A',
        error: '',
        logs: ''
      },
      {
        index: 2,
        title: 'Procesar archivo',
        icon: 'cached',
        color: this.color1,
        status: 'A',
        error: '',
        logs: ''
      },
    ];

    
    this.ENPOINT = environment.SERVER;
    this.formData = this.dataModal.formData;
    this.imagenes = this.dataModal.imagenes;
    this.columnas = this.imagenes ? 4:3; 
    if(this.imagenes){
      this.proccess.push(
        {
          index: 3,
          title: 'Enviar imagenes',
          icon: 'image',
          color: this.color1,
          status: 'A',
          error: '',
          logs: ''
        }
      )
    }
    this.sendData();
  }

  sendData(){
    this.loading = true;
    this.proccess[0].color = this.color2;
    this.proccess[0].status = 'B';
    this.proccess[0].title = 'Cargando archivo';
    this.FormatosService.uploadCargue(this.formData).subscribe(event=> {
      if (event.type === HttpEventType.UploadProgress){
        this.progress = Math.round(100 * event.loaded / event.total);
      }
      else if (event.type === HttpEventType.Response) {
        this.proccess[0].title = 'Archivo cargado correctamente';
        const CargueId = event.body.data.cargueID;
        this.proccess[0].color =this.color3;
        this.proccess[0].status = 'C';
        this.proccess[1].color = this.color2;
        this.proccess[1].status = 'B';
        this.proccess[1].title = 'Analizando archivo';
        this.FormatosService.analizarCargue(CargueId).subscribe(response1 =>{
          this.proccess[1].status = 'C';
          this.proccess[1].title = 'Archivo analizado correctamente';
          this.proccess[1].color = this.color3;
          this.proccess[2].color = this.color2;
          this.proccess[2].status = 'B';
          this.proccess[2].title = 'Procesando archivo';
          this.FormatosService.procesarCargue(CargueId).subscribe((response2: any) =>{
            this.proccess[2].status = 'C';
            this.proccess[2].title = 'Archivo procesado correctamente';
            this.proccess[2].color = this.color3;

            if(this.imagenes){
              const Formato = response2.data.formatoID;
              const formDataImagenes = new FormData();
              this.imagenes.value.controls.forEach(imagen => {
                formDataImagenes.append('Archivo', imagen.value);
              });
              formDataImagenes.append('CampoImagen', 'FOTO');
              formDataImagenes.append('CampoFiltro', 'CEDULA');
              formDataImagenes.append('CargueId', CargueId);
              formDataImagenes.append('Formato', `${Formato}_P1`);
              this.proccess[3].color = this.color2;
              this.proccess[3].status = 'B';
              this.proccess[3].title = 'Enviando imagenes';
              this.FormatosService.carguesImagenes(formDataImagenes).subscribe(response3 =>{
                if (response3.type === HttpEventType.UploadProgress){
                  this.progress2 = Math.round(100 * response3.loaded / response3.total);
                  console.log(this.progress2)
                }
                else if (event.type === HttpEventType.Response) {
                  this.proccess[3].status = 'C';
                  this.proccess[3].title = 'Imagenes enviadas correctamente';
                  this.proccess[3].color = this.color3;

                  this.cargueId = response2.data.cargueID;
                  if(response2.data.homologacionDepartamento){
                    this.linkHomologacion = '/homologacion/departamentos'
                  } else if(response2.data.homologacionMunicipio){
                    this.linkHomologacion = '/homologacion/municipios'
                  }else{
                    this.puedePublicar = true;
                  }
                  this.loading = false;
                }
              }, error => {
                this.proccess[3].title = 'Error al enviar las imagenes';
                this.proccess[3].color =this.color4;
                this.proccess[3].status = 'D';
                this.proccess[3].error = error.error.message;
              })
            }else{
              this.cargueId = response2.data.cargueID;
              if(response2.data.homologacionDepartamento){
                this.linkHomologacion = '/homologacion/departamentos'
              } else if(response2.data.homologacionMunicipio){
                this.linkHomologacion = '/homologacion/municipios'
              }else{
                this.puedePublicar = true;
              }
              this.loading = false;
            }
          }, error => {
            this.proccess[2].title = 'Error al procesar el archivo';
            this.proccess[2].color =this.color4;
            this.proccess[2].status = 'D';
            this.proccess[2].error = error.error.message;
            this.proccess[2].logs = error.error.data.log;
          })
        },error => {
          this.proccess[1].title = 'Error al analizar el archivo';
          this.proccess[1].color =this.color4;
          this.proccess[1].status = 'D';
          this.proccess[1].error = error.error.message;
          this.proccess[1].logs = error.error.data.log;
        })
      }
    }, error => {
      this.proccess[0].title = 'Error al cargar el archivo';
      this.proccess[0].color =this.color4;
      this.proccess[0].status = 'D';
      this.proccess[0].error = error.error.message;
      this.proccess[0].logs = error.error.data.log;
      
    })
  }

  publicarCargue(){
    this.FormatosService.setPublicar(this.cargueId).subscribe(res => {
      Swal.fire({
        title: 'Cargue publicado!',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.dialogRef.close();
    },(error) => {
      Swal.fire({
        title: 'Error al publicar!',
        text: error.error.message,
        icon: 'error',
        confirmButtonColor: 'success'
      });
      this.dialogRef.close();
    })
  }
}
 