import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartidosService } from './partidos.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../state/generales.actions';
import { MatDialog } from '@angular/material';
import { PartidoAddComponent } from "./partido-add/partido-add.component";
import { environment } from 'environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-Partidos',
  templateUrl: './partidos.component.html',
  styleUrls: ['./partidos.component.css']
})
export class PartidosComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  lista: any[] = [];
  pagination = {
    pageNumber: 1
  }
  SERVER: string
  constructor(
    public router: Router,
    private store: Store,
    private PartidosService: PartidosService,
    public dialog: MatDialog
  ) {

    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Partidos' }));

  }

  async ngOnInit() {
    this.SERVER = environment.SERVER;
    await this.getPartidos();
  }

  openDialog(PartidoID = 0): void {
    const dialogRef = this.dialog.open(PartidoAddComponent, {
      width: '750px',
      data: {PartidoID:PartidoID}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPartidos();
    });
  }
  async deletePartido(PartidoID){
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea eliminar este partido ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
    .then(async (willDelete) => {
      if (willDelete) {
        await this.PartidosService.deletePartido(PartidoID).subscribe((res: any) => {
          Swal.fire({
            title:'Eliminado correctamente',
            text: '',
            icon: 'success',
          });
          this.getPartidos();
      },(error) => {
          Swal.fire({
            title: 'Error al eliminar!',
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'success'
          });
        })
      }
    });
  }

  async getPartidos (page = 1) {
    await this.PartidosService.getList(page).subscribe((res: any) => {
      if(res.data.length>0){
        this.lista = res.data;
        this.lista.forEach(list => {
          if(list.logo == null){
            list.logo = '../../assets/partidoPolitico.png'
          }else{
            list.logo = this.SERVER + list.logo
          }
          return list;
        });
        this.pagination.pageNumber = res.pageNumber;
      }
    });
  }

}
