import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UsuariosService } from '../usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.scss']
})
export class RoleAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');

  UsuarioForm = new FormGroup({
    roles: new FormControl('', [Validators.required]),
  });
  submitted = false;
  error = {
    status: false,
    msg: ''
  };
  loading = false;
  edit = false;
  perfiles = [];
  constructor(
    private usuariosServices: UsuariosService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RoleAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  async ngOnInit() {

    await this.getListRoles();

    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/#/talleres';
  }

  // convenience getter for easy access to form fields

  async submitForm() {
    await this.usuariosServices.setUsuarioRole({ userId: this.data.id, roleName: this.UsuarioForm.controls.roles.value }).subscribe(res => {
      Swal.fire({
        title: "Registro exitoso",
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.dialogRef.close();
    });
  }
  async getListRoles() {
    await this.usuariosServices.getListRoles().subscribe((res: any) => {
      this.perfiles = res.data.filter(e => this.data.roles.find(a => a.id == e.id) == undefined);
    })
  }

  cerrar(){
    this.dialogRef.close();
  }

}
