import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
import {  HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private http: HttpRequestService
  ) { }


  getList(PageNumber = 1) {
    return this.http.getEntries(`Usuarios?PageNumber=${PageNumber}`);
  }
  getListRoles() {
    return this.http.getEntries('Usuarios/Roles');
  }
  getUsuario(id) {
    return this.http.getEntry('Usuarios',id);
  }
  setUsuario(model) {
    return this.http.createEntry('Usuarios',model);
  }
  putUsuario(model,id) {
    return this.http.updateEntry('Usuarios',id,model);
  }
  deleteUsuario(id) {
    return this.http.deleteEntry('Usuarios',id);
  }
  setUsuarioRole(model) {
    return this.http.createEntry('Usuarios/AsignarRolUsuario',model);
  }
  deleteUsuarioRole(model) {
    return this.http.createEntry('Usuarios/RemoverRolUsuario',model);
  }
  sendNotifications() {
    return this.http.accionPOST('Formatos/Reportar');
  }

}
