import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { AddGenerales } from 'app/state/generales.actions';
import { FormatosModalComponent } from './formatos/formatos.component';
import { HomeService } from './home.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  modules = [
    {
      modulo: "CGDPTO",
      title: 'CARACTERISTICAS GENERALES DEL DEPARTAMENTO',
      img: "../../../assets/img/icons/Caracteristicas generales del departamento.png",
      colorFondo:'color1',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "OF",
      title: 'OFERTA DE JUSTICIA',
      img: "../../../assets/img/icons/Oferta de justicia.png",
      colorFondo:'color4',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "NJD",
      title: 'NECESIDADES DE JUSTICIA',
      img: "../../../assets/img/icons/Necestidades de justicia.png",
      colorFondo:'color5',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "INPEC",
      title: 'INPEC',
      img: "../../../assets/img/icons/Inpec.png",
      colorFondo:'color2',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "USPEC",
      title: 'USPEC',
      img: "../../../assets/img/icons/Uspec.png",
      colorFondo:'color6',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "CDM",
      title: 'CARACTERISTICAS GENERALES DEL MUNICIPIO',
      img: "../../../assets/img/icons/Caracteristicas generales del municipio.png",
      colorFondo:'color3',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "OJM",
      title: 'OFERTA DE JUSTICIA MUNICIPAL',
      img: "../../../assets/img/icons/Oferta de justicia.png",
      colorFondo:'color7',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "NJM",
      title: 'NECESIDADES DE JUSTICIA MUNICIPAL',
      img: "../../../assets/img/icons/Necestidades de justicia.png",
      colorFondo:'color8',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "SNR",
      title: 'SUPERNOTARIADO Y REGISTRO',
      img: "../../../assets/img/icons/Supernotorio y registro.png",
      colorFondo:'color9',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "OJD",
      title: 'PROGRAMAS DE OFERTA DE JUSTICIA',
      img: "../../../assets/img/icons/Programa de oferta justicia.png",
      colorFondo:'color10',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
    {
      modulo: "PPD",
      title: 'PODER POLITICO DEL DEPARTAMENTO',
      img: "../../../assets/img/icons/Poder politico del departamento.png",
      colorFondo:'color11',
      formatos: [],
      numFormatosNecesarios: 0,
      formatosSinCarga: [],
      formatosDesactualizados: []
    },
];

  constructor(
    private homeService: HomeService,
    public dialog: MatDialog,
    private store: Store,
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Home' }));
  }

  ngOnInit() {
    this.getModulos();
  }

  getModulos(){
    this.homeService.getModulos().subscribe((res): any => {
      const modulos = res.data;
      modulos.forEach(modulo => {
        this.modules.map((m): any=> {
          const formatos = modulo.formatos;
          const numFormatosNecesarios = modulo.formatos.length;
          if(m.modulo == modulo.moduloID){
            const formatosSinCarga = [];
            const formatosDesactualizados = [];
            modulo.formatos.forEach(formato => {
              if(!formato.cargue){
                formatosSinCarga.push(formato);
              }
              if(formato.requiereActualizacion){
                formatosDesactualizados.push(formato);
              }
            });
            m.formatos = formatos;
            m.numFormatosNecesarios = numFormatosNecesarios;
            m.formatosSinCarga = formatosSinCarga;
            m.formatosDesactualizados = formatosDesactualizados;
          }
          return m;
        })
      });
    })
  }

  openDialog(formatos, moduloID): void {
    const dialogRef = this.dialog.open(FormatosModalComponent, {
      width: '1550px',
      data: {formatos, moduloID}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getModulos();
    });
  }
}
