import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
import {  HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpRequestService
  ) { }

  getModulos(): any{
    return this.http.getEntries('Modulos');
  }
}
