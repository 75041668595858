import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
import {  HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PreguntasService {

  constructor(
    private http: HttpRequestService
  ) { }


  getList(pageNumber=1) {
    return this.http.getEntries(`Preguntas?PageNumber=${pageNumber}&PageSize=10`);
  }
  getPregunta(id) {
    return this.http.getEntry('Preguntas',id);
  }
  setPregunta(model) {
    return this.http.createEntry('Preguntas',model);
  }
  
  putPregunta(model,id) {
    return this.http.updateEntry('Preguntas',id,model);
  }
  deletePregunta(id) {
    return this.http.deleteEntry('Preguntas',id);
  }
  
  getListCategories() {
    return this.http.getEntries('Categorias');
  }

  setCategoria(model) {
    return this.http.createEntry('Categorias',model);
  }
  sincronizar(){
    return this.http.accionPOST('Preguntas/sincronizar');
  }
}
