import { Component, Output, EventEmitter ,ViewChild,ElementRef} from '@angular/core';
import * as XLSX from 'xlsx';
type AOA = any[][];
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() documento = new EventEmitter();
  @Output() documentoStart = new EventEmitter();
  loading = false;
  excel: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  files: any ;

  uploadFile(event) {
    this.documentoStart.emit('');
    this.loading = true
    const element = event;
    this.excel = [];
    /* wire up file reader */
    /*const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');*/
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', sheetRows: 5 });
      
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      
      /* save data */
      let data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.excel = data.splice(0,6);
    };
    reader.readAsBinaryString(element);
    this.files = element.name
    
    this.loading = false
    this.documento.emit(element);
  }
  deleteAttachment() {
    this.excel = [];
    this.fileInput.nativeElement.value = '';
    this.files = null;
    console.log(this.excel);
    this.documento.emit('');
  }
}
