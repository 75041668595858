import { Component, Inject, OnInit } from '@angular/core';
import {  FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormatosService } from '../formatos.service'

import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-formatos-edit',
  templateUrl: './formatos-edit.component.html',
  styleUrls: ['./formatos-edit.component.scss']
})
export class FormatosEditComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  FormatoForm: FormGroup;
  loading = false
  submitted = false;

  constructor(
    private FormatosService: FormatosService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FormatosEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: any
  ) { }

  async ngOnInit() {
    if (this.dataModal) {
      this.FormatoForm = this.formBuilder.group({
        fuente: [this.dataModal.formato.fuente, [Validators.required]],
        DiasPeriodicidad: [this.dataModal.formato.diasPeriodicidad, [Validators.required, Validators.min(0)]],
      });
    }
  }

  get fuente() {
    return this.FormatoForm.controls.fuente;
  }
  get diasPeriodicidad() {
    return this.FormatoForm.controls.DiasPeriodicidad;
  }

  async submitForm() {
    this.loading = true;
    this.submitted = true;
    const formato = {
      formatoID: this.dataModal.formato.formatoID,
      nombre: this.dataModal.formato.nombre,
      // paginas: this.dataModal.formato.paginas,
      fuente: this.FormatoForm.controls.fuente.value,
      version: this.dataModal.formato.version,
      versionPublicada: this.dataModal.formato.versionPublicada,
      fechaPublicacion: this.dataModal.formato.fechaPublicacion,
      diasPeriodicidad: this.FormatoForm.controls.DiasPeriodicidad.value
    }

    this.FormatosService.putFormato(formato,this.dataModal.formato.formatoID).subscribe(res=> {
      Swal.fire({
        title: 'Formato actualizado!',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.loading = false
      this.dialogRef.close();
    }, error => {
      Swal.fire({
        title: 'Error al editar!',
        text: error.error.message,
        icon: 'error',
        confirmButtonColor: 'error'
      });
      this.loading = false
    })
  }

  cerrar(){
    this.dialogRef.close();
  }
}
