import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TalleresService } from './talleres.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../state/generales.actions';
import { MatDialog } from '@angular/material';
import { TalleresAddComponent } from "./talleres-add/talleres-add.component";
import { environment } from 'environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-talleres',
  templateUrl: './talleres.component.html',
  styleUrls: ['./talleres.component.css']
})
export class TalleresComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  talleres: any[] = [];
  loading = false;
  api: string;
  departamentos = []
  municipios = []
  departamentoSelect = null
  municipioSelect = null
  pagination = {
    pageNumber: 1,
    pageSize: 5,
    totalPages: 0
  }
  

  constructor(
    public router: Router,
    private store: Store,
    private formBuilder: FormBuilder,
    private TalleresService: TalleresService,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Talleres' }));
  }

  async ngOnInit() {
    this.api = environment.API;
    this.loading = true;
    await this.actualizarDepartamentos();
    this.loading = false;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TalleresAddComponent, {
      width: '1350px',
      data: {
        departamento: this.departamentoSelect.value,
        municipio: this.municipioSelect.value
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.actualizarTalleres(this.municipioSelect, this.pagination.pageNumber);
    });
  }

  actualizarTalleres(municipio = null, pageNumber = 1){
    this.municipioSelect  = municipio;
    this.loading = true;
    this.TalleresService.getTalleres(pageNumber, this.pagination.pageSize, this.departamentoSelect, this.municipioSelect).subscribe((result: any) => {
      this.pagination.pageNumber = result.pageNumber;
      this.pagination.totalPages = result.totalPages;
      if(result.data.length > 0){
        this.talleres = result.data.sort(function(a, b) {
          var nameA = a.nombre.toUpperCase();
          var nameB = b.nombre.toUpperCase();
          if (nameA < nameB) 
          return -1;
          if (nameA > nameB) 
          return 1;
          return 0;
        });
      }else {
        this.talleres = [];
      }
      this.loading = false;
    })
  }
  
  async actualizarDepartamentos(){
    return new Promise((resolve, reject)=> {
      this.TalleresService.getDepartamentos().subscribe(async (res: any) =>{
        // this.departamentos = res.data.sort((a,b) => a.nombre.localeCompare(b.nombre))
        this.departamentos = res.data.sort(function(a, b) {
          var nameA = a.nombre.toUpperCase();
          var nameB = b.nombre.toUpperCase();
          if (nameA < nameB) 
            return -1;
          if (nameA > nameB) 
            return 1;
          return 0;
        });
        await this.actualizarMunicipios();
        resolve(true)
      }, err=>{
        reject(err)
      })
    })
  }
  async actualizarMunicipios(departamento = null){
    this.departamentoSelect = departamento
    return new Promise(async (resolve, reject) =>{
      if(this.departamentoSelect == null){
        this.municipios = [];
        this.municipioSelect = null
        await this.actualizarTalleres();
        resolve(true)
      }else{
        this.TalleresService.getMunicipiosByDepartamento(this.departamentoSelect.value.departamentoID).subscribe( async(res: any) =>{
          this.municipios = res.data.municipios.sort(function(a, b) {
            var nameA = a.nombre.toUpperCase();
            var nameB = b.nombre.toUpperCase();
            if (nameA < nameB) 
            return -1;
            if (nameA > nameB) 
            return 1;
            return 0;
          });
          this.municipioSelect = null
          await this.actualizarTalleres();
          resolve(true)
        }, err=>{
          reject(err)
        })
      }
    })
  }

}
