import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormatosService } from '../formatos.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../../state/generales.actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CarguePublicarComponent } from '../cargue-publicar/cargue-publicar.component';
import { environment } from 'environments/environment';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormatosAddComponent } from '../formatos-add/formatos-add.component';

@Component({
  selector: 'app-formatos-cargues',
  templateUrl: './formatos-cargues.component.html',
  styleUrls: ['./formatos-cargues.component.css']
})
export class FormatosCarguesComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  environment = environment;
  lista: any[] = [];
  carguePublicado = null;
  pagination = {
    pageNumber: 1,
    totalPages: 0,
  }
  loadingTable = false;
  constructor(
    public router: Router,
    private store: Store,
    private FormatosService: FormatosService,
    public dialogRef: MatDialogRef<FormatosCarguesComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: any,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Formatos' }));
  }

  async ngOnInit() {
    this.getCargues()
    console.log(this.dataModal)
  }


  async getCargues(page = 1) {
    this.loadingTable = true;
    await this.FormatosService.getCarguesByFormato(this.dataModal.id, page).subscribe((data: any) => {
      this.pagination.pageNumber = data.pageNumber;
      this.pagination.totalPages = data.totalPages;
      if (data.data.length != 0) {
        this.lista = data.data.map(x => {

          if (x.observacion && x.observacion.length >= 100) {
            x.observacionCompacta = `${x.observacion.slice(0, 100)}...`
          } else {
            x.observacionCompacta = x.observacion;
          }

          x.carguePublicado = (x.version == this.dataModal.versionPublicada);

          x.procesarInvalid = x.publicado || x.estado === 'Erroneo' || x.estado === 'PendienteAnalisis';

          x.procesarTitle = x.estado === 'Erroneo' ?
            'No puede procesar si el estado del cargue es erroneo' :
            x.estado === 'PendienteAnalisis' ?
              'No puede procesar si está pendiente de analisis, se recomienda eliminar el cargue' :
              x.publicado ?
                'No se puede procesar si el cargue ya se ha publicado' :
                'Procesar'

          x.publicInvalid = x.estado === 'Erroneo' || (x.homologacionDepartamento || x.homologacionMunicipio) || x.estado === 'PendienteAnalisis';
          x.adjuntarInvalid = x.estado === 'Erroneo' || (x.homologacionDepartamento || x.homologacionMunicipio) || x.estado === 'PendienteAnalisis' || x.publicado;
          
          x.adjuntarTitle = x.estado === 'Erroneo' ?
            'No puede adjuntar si el estado del cargue es erroneo' :
            x.estado === 'PendienteAnalisis' ?
              'No puede adjuntar si está pendiente de analisis, se recomienda eliminar el cargue' :
              (x.homologacionDepartamento || x.homologacionMunicipio) ?
                'No puede adjuntar si hay casos de homologacion' :
                x.publicado ?
                  'No puede adjuntar si  el cargue ya fue publicado' :
                  'Adjuntar';

          x.publicTitle = x.estado === 'Erroneo' ?
            'No puede publicar si el estado del cargue es erroneo' :
            x.estado === 'PendienteAnalisis' ?
              'No puede publicar si está pendiente de analisis, se recomienda eliminar el cargue' :
              (x.homologacionDepartamento || x.homologacionMunicipio) ?
                'No puede publicar si hay casos de homologacion' :
                x.publicado ?
                  'El cargue ya esta publicado' :
                  'Publicar';

          return x;
        })
        this.carguePublicado = this.dataModal.cargue
      } else {
        this.lista = [];
      }
      this.loadingTable = false;
    });
  }

  openDialogPublicar(cargue, version = 0): void {
    const id = cargue.cargueID
    if ((cargue.carguePublicado) || (cargue.publicInvalid)) return;
    Swal.fire({
      title: "Esta seguro?",
      html: "Seguro que desea publicar este cargue ?\n\n\n <strong>Despues de publicar no podrá eliminar ni reprocesar este cargue!<strong>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar',
    })
      .then((willDelete) => {
        if (willDelete.value) {
          this.FormatosService.setPublicar(id).subscribe(res => {
            Swal.fire({
              title: 'Cargue publicado!',
              text: '',
              icon: 'success',
              confirmButtonColor: 'success'
            });
            this.dataModal.versionPublicada = version
            this.dataModal.cargue = this.lista.find(d => d.cargueID == id);
            this.getCargues(this.pagination.pageNumber);
          }, (error) => {
            Swal.fire({
              title: 'Error al publicar!',
              text: error.error.message,
              icon: 'error',
              confirmButtonColor: 'success'
            });
          })
        }
      });

    // const dialogRef = this.dialog.open(CarguePublicarComponent, {
    //   width: '550px',
    //   data: {id}
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.getCargues();
    // });
  }

  deleteCargue(cargue) {
    const id = cargue.cargueID
    if (cargue.publicado) return;
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea eliminar este cargue ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
      .then((willDelete) => {
        if (willDelete.value) {
          this.FormatosService.deleteCargue(id).subscribe(res => {
            Swal.fire({
              title: 'Cargue eliminado!',
              text: '',
              icon: 'success',
              confirmButtonColor: 'success'
            });
            this.getCargues();
          }, error => {
            Swal.fire({
              title: 'Error al eliminar!',
              text: error.error.message,
              icon: 'error',
              confirmButtonColor: 'success'
            });
          })
        }
      });
  }

  procesar(cargue) {
    const id = cargue.cargueID;
    if (cargue.procesarInvalid) return;

    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea reprocesar este cargue ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
      .then((willDelete) => {
        if (willDelete.value) {
          Swal.fire({
            title: 'Por favor espere',
            html: 'El cargue se esta procesando',// add html attribute if you want or remove
            allowOutsideClick: false,
            showConfirmButton: false ,
            onBeforeOpen: () => {
              Swal.showLoading()
              this.FormatosService.procesarCargue(id).subscribe(res => {
                Swal.close();
                Swal.fire({
                  title: 'Cargue procesado nuevamente!',
                  text: '',
                  icon: 'success',
                  confirmButtonColor: 'success'
                });
                this.getCargues(this.pagination.pageNumber);
              }, error => {
                Swal.close();
                Swal.fire({
                  title: 'Error al intentear procesar!',
                  text: error.error.message,
                  icon: 'error',
                  confirmButtonColor: 'success'
                });
              })
            },
          });
        }
      })
  }

  analizar(id) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea volver a analizar este cargue ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
      .then((willDelete) => {
        if (willDelete.value) {
          this.FormatosService.analizarCargue(id).subscribe(res => {
            Swal.fire({
              title: 'Cargue analizado nuevamente!',
              text: '',
              icon: 'success',
              confirmButtonColor: 'success'
            });
          }, error => {
            Swal.fire({
              title: 'Error al intentear analizar!',
              text: error.error.message,
              icon: 'error',
              confirmButtonColor: 'success'
            });
          })
        }
      })
  }

  cerrar() {
    this.dialogRef.close();
  }

  openDialog(item, id = 0): void {
    const dialogRef = this.dialog.open(FormatosAddComponent, {
      width: '1450px',
      data: {id, cargueID: item.cargueID}
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getFormatos();
    });
  }

}
