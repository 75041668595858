// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // API: 'https://localhost:44386/api/v1'
  // API: 'https://api.minjusticia.ml/api/v1',
  API:'https://appmovil.minjusticia.gov.co/api/v1',
  SERVER:'https://appmovil.minjusticia.gov.co/',
  // SERVER: 'https://api.minjusticia.ml/',
};
