import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { NoticiasService } from '../noticias.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-noticia-add',
  templateUrl: './noticia-add.component.html',
  styleUrls: ['./noticia-add.component.scss']
})
export class NoticiaAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');

  NoticiaForm = new FormGroup({
    titulo: new FormControl('', [Validators.required]),
    contenido: new FormControl('', [Validators.required]),
    portada: new FormControl('', [Validators.required]),
    entradilla:  new FormControl('', [Validators.required]),
    estado: new FormControl(0, [Validators.required]),
    destacada: new FormControl(false, [Validators.required]),
    fechaPublicacion: new FormControl(new Date().toString(), [Validators.required])
  });
  submitted = false;
  error = {
    status: false,
    msg: ''
  };
  loading = false;
  maxDate = new Date();
  edit = false;
  constructor(
    private NoticiasServices: NoticiasService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NoticiaAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  async ngOnInit() {
    if (this.data.NoticiaID != 0) {  
      await this.NoticiasServices.getNoticia(this.data.NoticiaID).subscribe((res: any) => {
        this.NoticiaForm.setValue({
          titulo: res.data.titulo,
          contenido: res.data.contenido,
          portada: res.data.portada,
          entradilla:res.data.entradilla,
          estado: res.data.estado,
          destacada: res.data.destacada,
          fechaPublicacion: res.data.fechaPublicacion
        });
        this.NoticiaForm.addControl('PortadaUpdate', new FormControl(''))
      });
      this.edit = true;
    }
  }
  setImagen(e) {
    if (this.edit) {
      this.NoticiaForm.controls.PortadaUpdate.setValue(e[0]);
    } else {
      this.NoticiaForm.controls.portada.setValue(e[0]);
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.NoticiaForm.controls;
  }

  get titulo() {
    return this.NoticiaForm.controls.titulo;
  }

  get entradilla() {
    return this.NoticiaForm.controls.entradilla;
  }
  get contenido() {
    return this.NoticiaForm.controls.contenido;
  }
  get portada() {
    return this.NoticiaForm.controls.portada;
  }
  get estado() {
    return this.NoticiaForm.controls.estado;
  }
  get destacada() {
    return this.NoticiaForm.controls.destacada;
  }
  get PortadaUpdate() {
    return this.NoticiaForm.controls.PortadaUpdate;
  }
  get fechaPublicacion() {
    return this.NoticiaForm.controls.fechaPublicacion;
  }

  async submitForm() {
    this.submitted = true;
    this.loading = true;
    if(!this.NoticiaForm.valid){
      Swal.fire({
        title: "Todos los campos son obligatorios",
        text: '',
        icon: 'error',
      });
      this.loading = false;
    }else{
      const formData = new FormData();
      if (this.edit) {
        formData.append('id', this.data.NoticiaID);
        formData.append('PortadaUpdate', this.PortadaUpdate.value);
      }
      formData.append('titulo', this.titulo.value);
      formData.append('contenido', this.contenido.value);
      formData.append('portada', this.portada.value);
      formData.append('entradilla', this.entradilla.value);
  
      formData.append('estado', this.estado.value);
      formData.append('destacada', this.destacada.value);
      formData.append('fechaPublicacion', moment(this.fechaPublicacion.value).format('YYYY-MM-DDTHH:MM:SS'));
      const service = !this.edit ?
        this.NoticiasServices.setNoticia(formData) :
        this.NoticiasServices.putNoticia(formData, this.data.NoticiaID);
  
      await service.subscribe((res: any) => {
        Swal.fire({
          title: (res)?res.message: !this.edit ?'Registro exitoso':'Noticia modificada correctamente',
          text: '',
          icon: 'success',
          confirmButtonColor: 'success'
        });
        this.dialogRef.close();
      },
        (error: any) => {
          Swal.fire({
            title: error.error.message,
            text: '',
            icon: 'error',
            confirmButtonColor: 'error',
            timer: 4000
          });
          this.loading = false;
        })
      
    }
  
  }

  cerrar(){
    this.dialogRef.close();
  }
}
