import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreguntasService } from './preguntas.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../state/generales.actions';
import { MatDialog } from '@angular/material';
import { PreguntaAddComponent } from "./pregunta-add/pregunta-add.component";
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css']
})
export class PreguntasComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  lista: any[] = [];
  loading = false;
  pagination = {
    pageNumber: 1
  }
  constructor(
    public router: Router,
    private store: Store,
    private PreguntasService: PreguntasService,
    public dialog: MatDialog
  ) {

    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Preguntas' }));

  }

  async ngOnInit() {
    await this.getPreguntas();
  }

  openDialog(preguntaID = 0): void {
    const dialogRef = this.dialog.open(PreguntaAddComponent, {
      width: '750px',
      data: {preguntaID:preguntaID}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPreguntas();
    });
  }
  async deletePregunta(preguntaID){
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea eliminar esta pregunta ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
    .then(async (willDelete) => {
      if (willDelete.value) {
        await this.PreguntasService.deletePregunta(preguntaID).subscribe((res: any) => {
          Swal.fire({
            title:'Eliminado correctamente',
            text: '',
            icon: 'success',
          });
          this.getPreguntas();
        },(error) => {
          Swal.fire({
            title: 'Error al eliminar!',
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'success'
          });
        });
      } 
    });
  }

  async getPreguntas (page = 1) {
    await this.PreguntasService.getList(page).subscribe((res: any) => {
      if(res.data.length != 0) {
        this.lista = res.data;
        this.pagination.pageNumber = res.pageNumber;
        this.lista.map(item => {
          if(item.respuesta.length>20){
            item.respuestaCompacta = `${item.respuesta.slice(0,100)}...` 
          }else{
            item.respuestaCompacta = `${item.respuesta}...` 
          }
          return item;
        })
      }else{
        this.lista = [];
      }
    });
  }
  async sincronizar () {
    this.loading = true;
    await this.PreguntasService.sincronizar().subscribe((res: any) => {
      this.getPreguntas();
      Swal.fire({
        title: (res)?res.message : 'Sincronizacion completada',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.loading = false;
    }, error => {
      this.loading = false;
      Swal.fire({
        title: (error)?error.error.Message : 'Error al sincronizar',
        text: '',
        icon: 'error',
        confirmButtonColor: 'error'
      });
    });
  }

}
