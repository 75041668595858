import { MinistroEditComponent } from './dashboard/ministro-edit/ministroEdit.component';
import { UploadPoliticosComponent } from './dashboard/component/upload-politicos/upload-politicos.component';
import { HomologacionMunicipiosComponent } from './page/homologaciones/municpios/homologacionMunicipios.component';
import { HomologacionDepartamentosComponent } from './page/homologaciones/departamentos/homologacionDepartamentos.component';
import { JobsEditComponent } from './dashboard/jobs-edit/jobs-edit.component';
import { FormatosEditComponent } from './page/formatos/formatos-edit/formatos-edit.component';
import { TalleresComponent } from './page/talleres/talleres.component';
import { TalleresAddComponent } from './page/talleres/talleres-add/talleres-add.component';
import { PenitenciarioAddComponent } from './page/epenitenciario/penitenciario-add/epenitenciario-add.component';
import { EpenitenciarioComponent } from './page/epenitenciario/epenitenciario.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './app.routes';
import { MatFormFieldModule, MatSelectModule,MatButtonModule, MatRadioModule, MatInputModule, MatMenuModule, MatCheckboxModule, MatCardModule, MatTooltipModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HomeComponent } from './dashboard/home/home.component';
import 'hammerjs';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FigurecardComponent } from './shared/figurecard/figurecard.component';
import { ImagecardComponent } from './shared/imagecard/imagecard.component';
import { MsgIconBtnComponent } from './shared/msgiconbtn/msgiconbtn.component';
import { LoginComponent } from './page/login/login.component';
import { RootComponent } from './dashboard/root/root.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PriceTableComponent } from './dashboard/component/pricetable/pricetable.component';
import { PreguntaAddComponent } from 'app/page/preguntas/pregunta-add/pregunta-add.component';
import { PreguntasComponent } from 'app/page/preguntas/preguntas.component';
import { NoticiaAddComponent } from 'app/page/noticias/noticia-add/noticia-add.component';
import { NoticiasComponent } from 'app/page/noticias/noticias.component';
import { FormatosAddComponent } from 'app/page/formatos/formatos-add/formatos-add.component';
import { FormatosComponent } from 'app/page/formatos/formatos.component';
import { UsuariosComponent } from './page/usuarios/usuarios.component';
import { RoleAddComponent } from './page/usuarios/role-add/role-add.component';
import { PartidoAddComponent } from 'app/page/partidos/partido-add/partido-add.component';
import { PartidosComponent } from 'app/page/partidos/partidos.component';
import { UserAddComponent } from 'app/page/usuarios/user-add/user-add.component';
import { FormatosCarguesComponent } from 'app/page/formatos/formatos-cargues/formatos-cargues.component';
import { CarguePublicarComponent } from 'app/page/formatos/cargue-publicar/cargue-publicar.component';
import { MatDatepickerModule,MatNativeDateModule,MatSlideToggleModule,MatChipsModule } from '@angular/material';
import { HttpClientModule} from "@angular/common/http";
import { SettingsService } from './services/settings.service';
import { UploadFileComponent } from './dashboard/component/upload-file/upload-file.component';
import { UploadImageComponent } from './dashboard/component/upload-image/upload-image.component';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { GeneralesState } from '../app/state/generales.state';
import { MatIconModule } from '@angular/material/icon';
import { FormatosModalComponent } from './dashboard/home/formatos/formatos.component';
import { SharedFormatosModalComponent } from './dashboard/component/formatos/shared-formatos.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ProcesoCargueComponent } from './page/formatos/proceso-cargue/proceso-cargue.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { StringFilterByPipe } from './pipes/StringFilterByPipe';

@NgModule({
  declarations: [
    PreguntasComponent,
    PreguntaAddComponent,
    NoticiasComponent,
    NoticiaAddComponent,
    FormatosAddComponent,
    FormatosComponent,
    AppComponent,
    SidebarComponent,
    HomeComponent,
    NavbarComponent,
    FigurecardComponent,
    ImagecardComponent,
    MsgIconBtnComponent,
    LoginComponent,
    RootComponent,
    HeaderComponent,
    FooterComponent,
    PriceTableComponent,
    UploadFileComponent,
    UploadImageComponent,
    UsuariosComponent,
    RoleAddComponent,
    PartidosComponent,
    PartidoAddComponent,
    UserAddComponent,
    FormatosCarguesComponent,
    CarguePublicarComponent,
    FormatosModalComponent,
    EpenitenciarioComponent,
    PenitenciarioAddComponent,
    TalleresAddComponent,
    TalleresComponent,
    UploadPoliticosComponent,
    SharedFormatosModalComponent,
    FormatosEditComponent,
    JobsEditComponent,
    HomologacionDepartamentosComponent,
    HomologacionMunicipiosComponent,
    MinistroEditComponent,
    ProcesoCargueComponent,
    StringFilterByPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    routing,
    BrowserAnimationsModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatInputModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
    CKEditorModule,
    MatGridListModule,
    NgxsModule.forRoot([GeneralesState], {
      developmentMode: !environment.production,
    }),
    MatNativeDateModule,
  ],
  providers: [SettingsService,MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
