import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuariosService } from './usuarios.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../state/generales.actions';
import { MatDialog } from '@angular/material';
import { UserAddComponent } from "./user-add/user-add.component";
import { RoleAddComponent } from "./role-add/role-add.component";
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  isAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  pagination = {
    pageNumber: 1,
    pageSize: 10
  }
  lista: any[] = [];
  constructor(
    public router: Router,
    private store: Store,
    private UsuariosService: UsuariosService,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Usuarios' }));
  }

  async ngOnInit() {

    await this.getlist();

  }
  async getlist(page=1) {
    await this.UsuariosService.getList(page).subscribe((data: any) => {
      if (data.data.length>0) {
        this.pagination.pageNumber = data.pageNumber;
        this.lista = data.data;
      }
    });
  }
  async removeRol(user, role) {
    if(!this.isAdmin) return;
    await this.UsuariosService.deleteUsuarioRole({ userId: user, roleName: role }).subscribe((data: any) => {
      Swal.fire({
        title: 'Eliminado correctamente',
        text: '',
        icon: 'success',
      });
      this.getlist();
    });

  }
  addRole(userId,roles): void {
    const dialogRef = this.dialog.open(RoleAddComponent, {
      width: '750px',
      data: { id: userId ,roles:roles}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getlist();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UserAddComponent, {
      width: '550px',
      data: null
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.getlist();
    });
  }
  openDialogEdit(user): void {
    const dialogRef = this.dialog.open(UserAddComponent, {
      width: '550px',
      data: {
        userId: user.userId,
        userName: user.userName,
        email: user.email
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.getlist();
    });
  }
  deleteUser(id): void {
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea eliminar este usuario ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
    .then(async (willDelete) => {
      if (willDelete.value) {
        await this.UsuariosService.deleteUsuario(id).subscribe((res: any) => {
          Swal.fire({
            title:'Eliminado correctamente',
            text: '',
            icon: 'success',
          });
          this.getlist(this.pagination.pageNumber);
        },(error) => {
            Swal.fire({
              title: 'Error al eliminar!',
              text: error.error.message,
              icon: 'error',
              confirmButtonColor: 'success'
            });
        });
      } 
    });
  }

  enviarNotificaciones(){
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea enviar las notificaciones a los usuarios ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    }).then( async (resp)  =>{
      console.log(resp)
      if( resp.isConfirmed){
        await this.UsuariosService.sendNotifications().subscribe((res: any) => {
          Swal.fire({
            title: (res.message) ? res.message : 'Notificaciones enviadas',
            text: '',
            icon: 'success',
            confirmButtonColor: 'success'
          });
          this.getlist()
        },error=>{
          Swal.fire({
            title: 'Error al intentar enviar las notificaciones',
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'error'
          });
        }
        
        )

      }
    })
  }
}

