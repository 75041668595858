import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
@Injectable({
  providedIn: 'root'
})
export class HomologacionService {

  constructor(
    private http: HttpRequestService
  ) { }

  getHomologacionesDepartamento(CargueID, PageNumber, PageSize) {
    let  url = `CargueHomologacion/Departamento?PageNumber=${PageNumber}&PageSize=${PageSize}`;
    if(CargueID!=0) url += `&CargueID=${CargueID}`;

    return this.http.getEntries(url);
  }
  getHomologacionesMunicipio(CargueID, PageNumber, PageSize) {
    let  url = `CargueHomologacion/Municipio?PageNumber=${PageNumber}&PageSize=${PageSize}`;
    if(CargueID!=0) url += `&CargueID=${CargueID}`;
    return this.http.getEntries(url);
  }

  postHomologacionesDepartamento(data) {
    return this.http.createEntry(`CargueHomologacion/Departamento`,data);
  }
  postHomologacionesMunicipio(data) {
    return this.http.createEntry(`CargueHomologacion/Municipio`,data);
  }
  deleteHomologacionesDepartamento(id) {
    return this.http.deleteEntry(`CargueHomologacion/Departamento`,id);
  }
  deleteHomologacionesMunicipio(id) {
    return this.http.deleteEntry(`CargueHomologacion/Municipio`,id);
  }


}
