import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormatosService } from './shared-formatos.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../../state/generales.actions';
import { FormatosAddComponent } from '../../../page/formatos/formatos-add/formatos-add.component';
import { FormatosEditComponent } from '../../../page/formatos/formatos-edit/formatos-edit.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormatosCarguesComponent } from '../../../page/formatos/formatos-cargues/formatos-cargues.component';
import { HomeService } from '../../home/home.service';
@Component({
  selector: 'app-modal-shared-formatos',
  templateUrl: './shared-formatos.component.html',
  styleUrls: ['./shared-formatos.component.css']
})
export class SharedFormatosModalComponent implements OnInit {
  @Input() lista: any[] = [];
  // @Input() dialog?: MatDialog = null;
  @Input() modal: boolean = false;
  @Input() loadingTable: boolean = false;
  @Output() actualizar = new EventEmitter<boolean>();
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Formatos' }));
  }

  async ngOnInit() {
    // this.actualizar.emit(true);
  }

  openDialogEditar(formato): void {
    const dialogRef = this.dialog.open(FormatosEditComponent, {
      width: '1050px',
      data: {formato}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.actualizar.emit(true);
    });
  }
  openDialogCargues(id = 0, nombre = '', versionPublicada, cargue): void {
    const dialogRef = this.dialog.open(FormatosCarguesComponent, {
      width: '1550px',
      data: {id, versionPublicada, cargue, nombre}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'Close'){
        this.dialog.closeAll();
      }
      this.actualizar.emit(true);
    });
  }

  openDialog(id = 0): void {
    const dialogRef = this.dialog.open(FormatosAddComponent, {
      width: '1450px',
      data: {id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.actualizar.emit(true);
    });
  }

  // async getFormatos() {
  //   this.loadingTable = true
  //   await this.FormatosService.getList().subscribe(async data => {
  //     if (data) {
  //       this.HomeService.getModulos().subscribe(async (result) =>{
  //         this.dataModal.formatos = result.data.find(entry => entry.moduloID === this.dataModal.moduloID).formatos
  //         // this.dataModal.formatos = this.dataModal.formatos
  //         this.lista = data;
  //         if(this.dataModal.formatos){
  //           const formatos = [];
  //           for await (const formatoA of this.lista) {
  //             for await (const formatoB of this.dataModal.formatos) {
  //               if(formatoA.formatoID == formatoB.formatoID)
  //               formatos.push({
  //                 ...formatoA,
  //                 requiereActualizacion: formatoB.requiereActualizacion,
  //                 cargue: formatoB.cargue,
  //                 // fechaCorte: formatoB.cargue.fechaCorte
  //               });
  //             }
  //           }
  //           this.lista = formatos.sort((a , b)=> parseInt(a.nombre.slice(0,2)) - parseInt(b.nombre.slice(0,2)));
  //         }
  //       })
  //     }
  //   });
  //   this.loadingTable = false
  // }

  cerrar(){
    this.dialog.closeAll();
  }
}
