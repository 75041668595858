import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PreguntasService } from '../preguntas.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-pregunta-add',
  templateUrl: './pregunta-add.component.html',
  styleUrls: ['./pregunta-add.component.scss']
})
export class PreguntaAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');

  preguntaForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required]),
    respuesta: new FormControl('', [Validators.required]),
    mjOrden: new FormControl('', [Validators.required]),
    categoriaID: new FormControl('', [Validators.required]),
    categoriaOtro: new FormControl('',)
  });
  submitted = false;
  error = {
    status: false,
    msg: ''
  };
  loading = false;
  edit = false;
  NewCategoria = false;
  categoria = '';
  constructor(
    private preguntasServices: PreguntasService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PreguntaAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  categories = [];

  async ngOnInit() {
    let dataDescripcion = '';
    let dataRespuesta = '';
    let categoriaID = '';
    let mjOrden = '';
    if (this.data.preguntaID != 0) {
      await this.preguntasServices.getPregunta(this.data.preguntaID).subscribe((res: any) => {
        dataDescripcion = res.data.descripcion;
        dataRespuesta = res.data.respuesta;
        mjOrden = res.data.mjOrden;
        categoriaID = res.data.categoria.categoriaID;
        this.preguntaForm.setValue({
          descripcion: dataDescripcion,
          respuesta: dataRespuesta,
          mjOrden: mjOrden,
          categoriaID: categoriaID,
          categoriaOtro: ''
        });
      });
      this.edit = true;
    } else {
      this.preguntaForm.setValue({
        descripcion: '',
        respuesta: '',
        mjOrden: '',
        categoriaID: 1,
        categoriaOtro: ''
      });
    }
    await this.getListCategories();

    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/#/talleres';
  }
  agregarCategoria() {
    if (this.NewCategoria) {
      this.NewCategoria = false;
      this.categoria = '';
    } else {
      this.NewCategoria = true;
    }

  }
  // convenience getter for easy access to form fields
  get f() {
    return this.preguntaForm.controls;
  }

  get descripcion() {
    return this.preguntaForm.controls.descripcion;
  }
  get respuesta() {
    return this.preguntaForm.controls.respuesta;
  }
  get categoriaID() {
    return this.preguntaForm.controls.categoriaID;
  }

  async submitForm() {
    if (!this.preguntaForm.valid) {
      Swal.fire({
        title: "Todos los campos son obligatorios",
        text: '',
        icon: 'error',
      });
      this.loading = false;
    } else {
      if (this.preguntaForm.value.categoriaOtro != '') {
        await this.preguntasServices.setCategoria({ nombre: this.preguntaForm.value.categoriaOtro }).subscribe(async res => {
          this.preguntaForm.controls.categoriaID.setValue(res.data.categoriaID);
          this.preguntaForm.removeControl("categoriaOtro");
          const service = !this.edit ?
            this.preguntasServices.setPregunta({ preguntaID: this.data.preguntaID, ...this.preguntaForm.value }) :
            this.preguntasServices.putPregunta({ preguntaID: this.data.preguntaID, ...this.preguntaForm.value }, this.data.preguntaID);

          await service.subscribe((res: any) => {
            Swal.fire({
              title: res.message,
              text: '',
              icon: 'success',
              confirmButtonColor: 'success'
            });
          },
            (error: any) => {
              console.log(error)
              Swal.fire({
                title: error.error.message,
                text: '',
                icon: 'error',
                confirmButtonColor: 'error',
                timer: 1000
              });
            })
          this.dialogRef.close();
        });
      } else {
        const service = !this.edit ?
          this.preguntasServices.setPregunta({ preguntaID: this.data.preguntaID, ...this.preguntaForm.value }) :
          this.preguntasServices.putPregunta({ preguntaID: this.data.preguntaID, ...this.preguntaForm.value }, this.data.preguntaID);

        await service.subscribe((res: any) => {
          Swal.fire({
            title: 'Pregunta editada',
            text: '',
            icon: 'success',
            confirmButtonColor: 'success'
          });
          this.dialogRef.close();
        },
          (error: any) => {
            Swal.fire({
              title: error.error.message,
              text: '',
              icon: 'error',
              confirmButtonColor: 'error',
              timer: 4000
            });
          })
      }
    }
  }
  
  async getListCategories() {
    await this.preguntasServices.getListCategories().subscribe((res: any) => {
      this.categories = res.data;
    })
  }

  cerrar() {
    this.dialogRef.close();
  }

}
