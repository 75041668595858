import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../services/httpRequest.service';
import {  HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PartidosService {

  constructor(
    private http: HttpRequestService
  ) { }


  getList(page = 1) {
    return this.http.getEntries(`PartidosPoliticos?pageNumber=${page}`);
  }
  getPartido(id) {
    return this.http.getEntry('PartidosPoliticos',id);
  }
  setPartido(model) {
    return this.http.uploadAndData('PartidosPoliticos',model);
  }
  
  putPartido(model,id) {
    return this.http.uploadAndUpdateData('PartidosPoliticos',id,model);
  }
  deletePartido(id) {
    return this.http.deleteEntry('PartidosPoliticos',id);
  }
  
}
