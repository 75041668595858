import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = {
    status: false,
    msg: ''
  };
  loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get email() {
    return this.loginForm.controls.email;
  }
  get password() {
    return this.loginForm.controls.password;
  }

  async onSubmit() {
    this.submitted = true;
    this.loading = true;
    await this.authService.login(this.f.email.value, this.f.password.value).subscribe((res: any) => {
      if(res.data.roles.includes('ADMINISTRADOR') || res.data.roles.includes('USUARIO')){
        this.router.navigate(['/home']);
      }else{
        this.error = {
          status: true,
          msg: 'No tiene permisos para acceder',
        };
      }
      this.loading = false;
    },
      error => {
        this.error = {
          status: true,
          msg: error.error.message,
        };
        this.loading = false;
      }
    );

  }
}
