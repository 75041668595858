
import { PenitenciarioAddComponent } from './penitenciario-add/epenitenciario-add.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PenitenciarioService } from './epenitenciario.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../state/generales.actions';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';


import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-epenitenciario',
  templateUrl: './epenitenciario.component.html',
  styleUrls: ['./epenitenciario.component.css']
})
export class EpenitenciarioComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  lista: any[] = [];
  pagination = {
    pageNumber: 1,
    totalPages: 0
  }
  filter = ''
  constructor(
    public router: Router,
    private store: Store,
    private pService: PenitenciarioService,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Establecimientos penitenciarios' }));
  }
  selectedEstablecimiento:any
   SERVER:string=environment.SERVER;

  async ngOnInit() {

    await this.getlist();

  }
  async getlist (page = 1) {
    await this.pService.getList(page, this.filter).subscribe((res: any) => {
      this.pagination.pageNumber = res.pageNumber;
      this.pagination.totalPages = res.totalPages;
      if(res.data.length != 0) {
        this.lista = res.data;
        this.lista.map(item => {
          console.log("ITEM=>",item)
          if(item.foto== null){
              item.foto = 'http://www.pequenomundo.cl/wp-content/themes/childcare/images/default.png'
            }else{
              item.foto = this.SERVER + item.foto
            }
            return item;
          })
      }else{
        this.lista = [];
      }
    });
  }

  seleccionarEstablecimiento(item){
   this.selectedEstablecimiento=item;
   console.log("SELECTED ESTABLECIMIENTO=>",this.selectedEstablecimiento)
   this.openDialog(item)
  }
 /* async removeRol(user, role) {
    await this.pService.deleteUsuarioRole({ userId: user, roleName: role }).subscribe((data: any) => {
      Swal.fire({
        title: 'Eliminado correctamente',
        text: '',
        icon: 'success',
      });
      this.getlist();
    });

  }*/
  /*  addRole(userId,roles): void {
  const dialogRef = this.dialog.open(RoleAddComponent, {
      width: '750px',
      data: { id: userId ,roles:roles}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getlist();
    });
  }*/

  openDialog(data): void {
   const dialogRef = this.dialog.open(PenitenciarioAddComponent, {
      width: '800px',
      data: data?data:null
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.getlist();
      this.selectedEstablecimiento=null
    });
  }

  async deleteEstablecimiento(id){
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea eliminar este establecimiento ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    }).then( async (resp)  =>{
      console.log(resp)
      if( resp.isConfirmed){
        await this.pService.deleteEstablecimiento(id).subscribe(res => {
          Swal.fire({
            title: "Establecimiento eliminado correctamente",
            text: '',
            icon: 'success',
            confirmButtonColor: 'success'
          });
          this.getlist()
        },error=>{
          Swal.fire({
            title: 'Error al eliminar!',
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'error'
          });
        }
        
        )

      }
    })
   
   
  }
}

