
import { Component ,Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent {
  @Output() imagenes = new EventEmitter();
 
  files: any = [];
  images:any = [];
  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      const reader = new FileReader();
      reader.readAsDataURL(element);
    
      reader.onload = () => {
        this.images.push(reader.result);
      };
      this.files.push(element);
      
    }
    this.imagenes.emit(this.files);
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
    this.images.splice(index, 1)
    this.imagenes.emit(this.files);
  }
}
