import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormatosService } from '../formatos.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-cargue-publicar',
  templateUrl: './cargue-publicar.component.html',
  styleUrls: ['./cargue-publicar.component.css']
})
export class CarguePublicarComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');

  constructor(
    private FormatosService: FormatosService,
    public dialogRef: MatDialogRef<CarguePublicarComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: any,
  ) { }

  ngOnInit() {
  }

  cancelar() {
    this.dialogRef.close();
  }

  publicar(){
    this.FormatosService.setPublicar(this.dataModal.id).subscribe(res => {
      Swal.fire({
        title: 'Cargue publicado!',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
    });
  }
}
