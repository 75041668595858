import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PenitenciarioService } from '../epenitenciario.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-epenitenciario-add',
  templateUrl: './epenitenciario-add.component.html',
  styleUrls: ['./epenitenciario-add.component.scss']
})
export class PenitenciarioAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
 penitenciarioForm: FormGroup;
  submitted = false;
  error = {
    status: false,
    msg: ''
  };
  loading = false;
  edit = false;
  idmun=-1
  iddep=-1
  errmun=false
  errdepto=false  
  departamentos = [];
  municipios = [];
  selectedEstablecimiento:any
  SERVER:string=environment.SERVER;
  constructor(
    private pServices: PenitenciarioService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PenitenciarioAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 

 
   
    if(data){
      this.actualizarDepartamentos()
    }else{
      this.actualizarDepartamentos()
    }
    
   
  }


  async ngOnInit() {
    this.penitenciarioForm = this.formBuilder.group({
      id: ['',[Validators.required]],
      mun: ['',[Validators.required]],
      depto: ['',[Validators.required]],
      nombre: ['', [Validators.required,Validators.nullValidator]],
      portada: new FormControl('', [Validators.required]),
    });

    if(this.data){
      this.getdepartamento.setValue(this.data.departamentoID);
      this.getmunicipio.setValue(this.data.municipioID)
   

      this.selectedEstablecimiento=this.data     
      this.actualizarMunicipios(this.data.departamentoID) 
      this.iddep=this.data.departamentoID;
      this.idmun=this.data.municipioID;
      
      this.penitenciarioForm.setValue({
        portada: this.data.foto.replace(this.SERVER,""),
        id:this.data.establecimientoID,
        nombre: this.data.nombre,
        mun:this.data.municipioID,
        depto:this.data.departamentoID
      })
      this.penitenciarioForm.controls.id.disable();
      this.edit=true;
     
      this.penitenciarioForm.addControl('FotoUpdate', new FormControl(''));
    }
   
  
  }
 

  async actualizarDepartamentos(){
    return new Promise((resolve, reject)=> {
      this.pServices.getDepartamentos().subscribe(async (res: any) =>{
        // this.departamentos = res.data.sort((a,b) => a.nombre.localeCompare(b.nombre))
        this.departamentos = res.data
        resolve(true)
      }, err=>{
        reject(err)
      })
    })
  }

  async actualizarMunicipios(idedep:string){
    console.log(this.penitenciarioForm.controls.depto.value);
    this.getmunicipio.setValue("-1")
    
      if(this.penitenciarioForm.controls.depto.value!="-1"){
        return new Promise(async (resolve, reject) =>{
          this.pServices.getMunicipiosByDepartamento(idedep!=null?idedep:this.penitenciarioForm.controls.depto.value).subscribe( async(res: any) =>{
            // this.municipios = res.data.municipios.sort((a,b) => a.nombre.localeCompare(b.nombre))
            this.municipios = res.data.municipios
          
            resolve(true)
          }, err=>{
            reject(err)
          })
        })
      }
     
  }
 
  


  get f() {
    return this.penitenciarioForm.controls;
  }
  get getportada() {
    return this.penitenciarioForm.controls.portada;
  }

  get getnombre() {
    return this.penitenciarioForm.controls.nombre;
  }
  get getdepartamento() {
    return this.penitenciarioForm.controls.depto;
  }
  get getmunicipio() {
    return this.penitenciarioForm.controls.mun;
  }
  get getId() {
    return this.penitenciarioForm.controls.id;
  }

  setImagen(e) {
    console.log(e)
    if (this.edit) {
      this.penitenciarioForm.controls.FotoUpdate.setValue(e[0]);
      
    } else {
      this.penitenciarioForm.controls.portada.setValue(e[0]);
    }
  }
 

  // convenience getter for easy access to form fields
  async submitForm() {
    this.submitted = true
    this.loading = true;

    if(this.getdepartamento.value=='-1' || this.getmunicipio.value=='-1' || this.getportada.value==null  || this.getportada.value==""){
      Swal.fire({
        title: "Todos los campos son obligatorios",
        text: '',
        type: 'error',
        confirmButtonClass: 'btn btn-success'
      });
      return false;
    }
   
 

    if(!this.penitenciarioForm.valid){
     
    
    }else{
      const formData = new FormData();
      formData.append("establecimientoID",this.getId.value?this.getId.value:this.selectedEstablecimiento.establecimientoID);
      formData.append("nombre",this.getnombre.value);
      formData.append("departamentoID",this.getdepartamento.value)
      formData.append("municipioID",this.getmunicipio.value)
      formData.append("foto",this.getportada.value)
      this.edit && formData.append("establecimientoID",this.data.establecimientoID)
      this.edit &&  formData.append('fotoUpdate',this.penitenciarioForm.controls.FotoUpdate.value);
      //console.log("FOTO =>"+this.getportada.value)
      //console.log("FOTO UPDATE =>"+this.penitenciarioForm.controls.FotoUpdate.value)
      /*console.log("FOTO ESTABLECIMIENTO");
      console.log(formData.get("fotoUpdate"));*/


     
   this.edit==false?   this.pServices.setEstablecimiento(formData).subscribe(res=>{
      /* console.log("RESPUESTA API");
        console.log(res.message);*/
      if(res.message.indexOf("Exception") > -1){

          Swal.fire({
            title: "El codigo de establecimiento ya existe",
            text: '',
            type: 'error',
            confirmButtonClass: 'btn btn-success'
          });
        
        }else{
          Swal.fire({
            title: "Establecimiento creado correctamente",
            text: '',
            type: 'success',
            confirmButtonClass: 'btn btn-success'
          });
          this.dialogRef.close();
        }

        
        
     

      },err=>{
        Swal.fire({
          title: "Error al  guardar",
          text: '',
          type: 'error',
          confirmButtonClass: 'btn btn-success'
        });
      }) :this.pServices.putEstablecimiento(formData,this.data.establecimientoID).subscribe(res=>{

     
        Swal.fire({
          title: "Establecimiento actualizado correctamente",
          text: '',
          type: 'success',
          confirmButtonClass: 'btn btn-success'
        });
        this.dialogRef.close();



      },err=>{
        Swal.fire({
          title: "Error al  guardar",
          text: '',
          type: 'error',
          confirmButtonClass: 'btn btn-success'
        });
      this.dialogRef.close();

    });
  }
   /* await this.pServices.setUsuario(this.penitenciarioForm.value).subscribe(res => {
      swal({
        title: "Registro exitoso",
        text: '',
        type: 'success',
        confirmButtonClass: 'btn btn-success'
      });
      this.dialogRef.close();
    });*/
    }

  cerrar(){
    this.dialogRef.close();
  }


}

