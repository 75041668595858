import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material';
import { HomologacionService } from '../homologacion.service';
import { FormatosService } from 'app/dashboard/home/formatos/formatos.service';
import { environment } from 'environments/environment';
import { TalleresService } from 'app/page/talleres/talleres.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AddGenerales } from 'app/state/generales.actions';

@Component({
  selector: 'app-homologacionMunicipios',
  templateUrl: './homologacionMunicipios.component.html',
  styleUrls: ['./homologacionMunicipios.component.css']
})
export class HomologacionMunicipiosComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  paginationCargues = {
    pageNumber: 1,
    totalPages: 0
  }
  casos: any[] = [];
  lista: any[] = [];
  formatos: any[] = [];
  cargues: any[] = [];
  environment = environment;
  loadingTable = false;
  municipioID = 0;
  sending = false;


  departamentos: any[] = [];
  municipios: any[] = [];

  formatoID = 0;
  cargueID = 0;

  showBtnProcesar = false;
  showBtnPublicar = false;

  pagination = {
    pageNumber: 1,
    pageSize: 30,
    totalPages: 0
  }

  casosHomologacion: any[] = [];
  constructor(
    public router: Router,
    private routersParam: ActivatedRoute,
    private store: Store,
    private HomologacionService: HomologacionService,
    private FormatosService: FormatosService,
    private TalleresService: TalleresService,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Homologacion de municipios' }));
  }

  async ngOnInit() {
    // this.actualizarDepartamentos();
    await this.getFormatos()
    // await this.actualizarMunicipios()
    await this.getHomologacionMunicipios()
    this.routersParam.queryParams.subscribe(params => {
      const cargueID = params["cargueID"]
      if(cargueID){
        this.FormatosService.getCargue(cargueID).subscribe((res: any)=> {
          const cargue = res.data;
          this.formatoID = cargue.formatoID;
          this.actualizarCargues(cargueID);
        });
      }
    })

  }

  async getHomologacionMunicipios(page = 1) {
    if(this.cargueID !== -1){
      this.loadingTable = true;
      await this.HomologacionService.getHomologacionesMunicipio(this.cargueID, page, this.pagination.pageSize).subscribe(async (result: any) => {
        this.pagination.pageNumber = result.pageNumber;
        this.pagination.totalPages = result.totalPages;
        if (result.data.length>0) {
          this.lista = this.casos = result.data;
          if(this.formatoID != 0)
            this.lista = this.casos.filter(l=>l.cargue.formatoID === this.formatoID)
          this.lista.map(async l=>{
            l.municipios = await this.getMunicipios(l.departamento.departamentoID);
            return l;
          });
          this.showBtnProcesar = false; 
          this.showBtnPublicar = false;
        }else{
          this.lista = []
          const cargueActual = this.cargues.find(d=>d.cargueID == this.cargueID);
          if(cargueActual){
            if(!cargueActual.publicado && cargueActual.estado !== 'Erroneo'){
              this.showBtnProcesar = true; 
              this.showBtnPublicar = true;

            }else{
              this.showBtnProcesar = false; 
              if(cargueActual.publicado)
                this.showBtnPublicar = true; 
            }
          }
        }
        this.loadingTable = false   ;
      });
    }else{
      this.actualizarCargues(0,this.paginationCargues.pageNumber + 1)
    } 
  }

  getFormatos(){
    this.FormatosService.getList().subscribe((result: any)=> {
      if(result){
        this.formatos = result.sort((a , b)=> parseInt(a.nombre.slice(0,2)) - parseInt(b.nombre.slice(0,2)));;
      }
    })
  }
  actualizarCargues(inCargueID = 0, pageNumber = 1){
    // this.lista = this.casos.filter(l=>l.cargue.formatoID === this.formatoID)
    this.cargueID =  0
    this.FormatosService.getCarguesByFormato(this.formatoID,pageNumber).subscribe((result: any)=> {
      if(result.data.length > 0){
        this.cargues = pageNumber == 1 ? result.data : [...this.cargues, ...result.data ];
        this.cargueID = inCargueID == 0 ? this.cargues[0].cargueID : inCargueID
        if(pageNumber == 1)
          this.getHomologacionMunicipios();
        this.paginationCargues.pageNumber = result.pageNumber;
        this.paginationCargues.totalPages = result.totalPages;
      }else{
        this.cargues = [];
        this.cargueID =  0
        this.lista = [];
      }
    })
  }

  ignoararCaso(casoID){
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea ignorar este caso de homologacion ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
    .then((willDelete) => {
      if (willDelete.value) {
        this.HomologacionService.deleteHomologacionesMunicipio(casoID).subscribe(res => {
          Swal.fire({
            title: 'Caso eliminado correctamente!',
            text: '',
            icon: 'success',
            confirmButtonColor: 'success'
          });
          this.getHomologacionMunicipios();
        }, error => {
          Swal.fire({
            title: 'Error al eliminar!',
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'success'
          });
        })
      }
    });
  }

  // async actualizarDepartamentos(){
  //   return new Promise((resolve, reject)=> {
  //     this.TalleresService.getDepartamentos().subscribe(async (res: any) =>{
  //       this.departamentos = res.data
  //       resolve(true)
  //     }, err=>{
  //       reject(err)
  //     })
  //   })
  // }

  async actualizarMunicipios(){
    return new Promise(async (resolve, reject) =>{
      this.TalleresService.getMunicipios().subscribe( async(res: any) =>{
        this.municipios = res.data.municipios
        resolve(true)
      }, err=>{
        reject(err)
      })
    })
  }

  agregarData(idMunicipio, caso){
    console.log(caso)
    const find = this.casosHomologacion.find(c=> c.id == caso.id);
    if(!find){
      this.casosHomologacion.push( {
        id: caso.id,
        noM_MPIO: caso.noM_MPIO,
        municipioID: idMunicipio,
        departamentoID: caso.departamentoID
      })
    } else {
      this.casosHomologacion =
        this.casosHomologacion.map( c=>
          c.id == caso.id ? {
            id: caso.id,
            noM_MPIO: caso.noM_MPIO,
            municipioID: idMunicipio,
            departamentoID: caso.departamentoID
          } : c
        )
    }
  }

  removeItem(arr, index){
    for( var i = 0; i < arr.length; i++){
      if ( arr[i] === index) {
          arr.splice(i, 1);
      }
    }
    return arr;
  }

  async getMunicipios(departamentoID){
    return new Promise((resolve, reject) => {
      this.TalleresService.getMunicipiosByDepartamento(departamentoID).subscribe((result: any) => {
        resolve(result.data.municipios)
      },(error) => {
        reject([])
      })
    })
  }

  enviarTodo(){
    this.sending = true;
    this.HomologacionService.postHomologacionesMunicipio(this.casosHomologacion).subscribe(async (res: any) => {
      Swal.fire({
        title: 'Casos de homologacion registrados!',
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.getHomologacionMunicipios();
      this.sending = false;
    },(error) => {
      console.log(error)
      Swal.fire({
        title: 'Error!',
        text: error.error.message,
        icon: 'error',
        confirmButtonColor: 'success'
      });
    })
  }

  procesar(id){
    Swal.fire({
      title: "Esta seguro?",
      text: "Seguro que desea reprocesar este cargue ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    })
    .then((willDelete) => {
      if(willDelete.value){
        this.FormatosService.procesarCargue(id).subscribe(res => {
          Swal.fire({
            title: 'Cargue procesado nuevamente!',
            text: '',
            icon: 'success',
            confirmButtonColor: 'success'
          });
          this.getHomologacionMunicipios()
        }, error => {
          Swal.fire({
            title: 'Error al intentear procesar!',
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'success'
          });
        })
      }
    })
  }

  publicar(id): void {
    Swal.fire({
      title: "Esta seguro?",
      html: "Seguro que desea publicar este cargue ?\n\n\n <strong>Despues de publicar no podrá eliminar ni reprocesar este cargue!<strong>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar',
    })
    .then((willDelete) => {
      if (willDelete.value) {
        this.FormatosService.setPublicar(id).subscribe(res => {
          Swal.fire({
            title: 'Cargue publicado!',
            text: '',
            icon: 'success',
            confirmButtonColor: 'success'
          });
          this.router.navigate(['/home']);
        },(error) => {
          Swal.fire({
            title: 'Error al publicar!',
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'success'
          });
        })
      }
    });
  }
  

}
