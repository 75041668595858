import { Injectable } from '@angular/core';
import { HttpRequestService } from 'app/services/httpRequest.service';
@Injectable({
  providedIn: 'root'
})
export class MinistroService {

  constructor(
    private http: HttpRequestService
  ) { }
  getMinistro() {
    return this.http.getEntries('EstructuraOrganizacional/MINISTROJUSTICIADERECHO');
  }
  putMinistro(model,id) {
    return this.http.accionPUT(`EstructuraOrganizacional/${id}`,model);
  }
  
}
