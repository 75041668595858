import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UsuariosService } from '../usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  usuarioForm: FormGroup;
  submitted = false;
  error = {
    status: false,
    msg: ''
  };
  edit = false;
  loading = false;
  perfiles = [];
  constructor(
    private usuariosServices: UsuariosService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UserAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  async ngOnInit() {
    this.usuarioForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      email: ['', [Validators.required, Validators.email]],
    });
    if (this.data.userId != 0) {  
      this.usuarioForm.setValue({
        userName: this.data.userName,
        email: this.data.email,
      });
      this.edit = true;
    }
    // await this.getListRoles();
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/#/talleres';
  }

  get f() {
    return this.usuarioForm.controls;
  }

  get userName() {
    return this.usuarioForm.controls.userName;
  }
  get email() {
    return this.usuarioForm.controls.email;
  }

  // convenience getter for easy access to form fields
  async submitForm() {
    this.submitted=true;
    this.loading=true;

    const service = !this.edit ?
    this.usuariosServices.setUsuario(this.usuarioForm.value) :
    this.usuariosServices.putUsuario({userId: this.data.userId ,email: this.usuarioForm.value.email}, this.data.userId);

    await service.subscribe(res => {
      Swal.fire({
        title: (res)?res.message:"Registro exitoso" ,
        text: '',
        icon: 'success',
        confirmButtonColor: 'success'
      });
      this.dialogRef.close();
      this.loading=true;
    }, (error) => {
        if(error.error.message){
          Swal.fire({
            title: "Error al registrar",
            text: error.error.message,
            icon: 'error',
            confirmButtonColor: 'error'
          });
        }
      this.loading = true;
    });
  }
  // async getListRoles() {
  //   await this.usuariosServices.getListRoles().subscribe((res: any) => {
  //     this.perfiles = res.data.filter(e => this.data.roles.find(a => a.id == e.id) == undefined);
  //   })
  // }

  cerrar(){
    this.dialogRef.close();
  }

}
