import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormatosService } from './formatos.service'
import { Store } from '@ngxs/store';
import { AddGenerales } from '../../../state/generales.actions';
import { FormatosAddComponent } from '../../../page/formatos/formatos-add/formatos-add.component';
import { FormatosEditComponent } from '../../../page/formatos/formatos-edit/formatos-edit.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormatosCarguesComponent } from '../../../page/formatos/formatos-cargues/formatos-cargues.component';
import { HomeService } from '../home.service';
@Component({
  selector: 'app-modal-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: ['./formatos.component.css']
})
export class FormatosModalComponent implements OnInit {
  roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : '[]';
  idAdmin = JSON.parse(this.roles).includes('ADMINISTRADOR');
  listaFormatos: any[] = [];
  loadingTable = false;
  modal = true;
  dialogLocal: MatDialog;
  constructor(
    public router: Router,
    private store: Store,
    private FormatosService: FormatosService,
    private HomeService: HomeService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dataModal: any,
  ) {
    this.store.dispatch(new AddGenerales({ name: 'titulo', valor: 'Formatos' }));
    this.dialogLocal = this.dialog;
  }

  async ngOnInit() {
    this.getFormatos();
  }
  
  async getFormatos() {
    this.loadingTable = true
    await this.FormatosService.getList().subscribe(async data => {
      if (data) {
        this.HomeService.getModulos().subscribe(async (result) =>{
          this.dataModal.formatos = result.data.find(entry => entry.moduloID === this.dataModal.moduloID).formatos
          // this.dataModal.formatos = this.dataModal.formatos
          this.listaFormatos = data;
          if(this.dataModal.formatos){
            const formatos = [];
            for await (const formatoA of this.listaFormatos) {
              for await (const formatoB of this.dataModal.formatos) {
                if(formatoA.formatoID == formatoB.formatoID)
                formatos.push({
                  ...formatoA,
                  requiereActualizacion: formatoB.requiereActualizacion,
                  cantidadCargues: formatoB.cantidadCargues ? formatoB.cantidadCargues : 0,
                  cargue: formatoB.cargue,
                  // fechaCorte: formatoB.cargue.fechaCorte
                });
              }
            }
            this.listaFormatos = formatos.sort((a , b)=> parseInt(a.nombre.slice(0,2)) - parseInt(b.nombre.slice(0,2)));
          }
        })
      }
    });
    this.loadingTable = false
  }
  
  cerrar(){
    this.dialog.closeAll();
  }

}

