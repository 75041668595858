import { PenitenciarioAddComponent } from './page/epenitenciario/penitenciario-add/epenitenciario-add.component';
import { EpenitenciarioComponent } from './page/epenitenciario/epenitenciario.component';
/**
 * Created by wangdi on 26/5/17.
 */
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './dashboard/home/home.component';
import { PreguntaAddComponent } from 'app/page/preguntas/pregunta-add/pregunta-add.component';
import { PreguntasComponent } from 'app/page/preguntas/preguntas.component';
import { NoticiaAddComponent } from 'app/page/noticias/noticia-add/noticia-add.component';
import { NoticiasComponent } from 'app/page/noticias/noticias.component';
import { FormatosAddComponent } from 'app/page/formatos/formatos-add/formatos-add.component';
import { FormatosComponent } from 'app/page/formatos/formatos.component';
import { PartidoAddComponent } from 'app/page/partidos/partido-add/partido-add.component';
import { PartidosComponent } from 'app/page/partidos/partidos.component';
import { PriceTableComponent } from './dashboard/component/pricetable/pricetable.component';
import { UsuariosComponent } from './page/usuarios/usuarios.component';
import { RoleAddComponent } from 'app/page/usuarios/role-add/role-add.component';
import { RootComponent } from './dashboard/root/root.component';
import { LoginComponent } from './page/login/login.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { UserAddComponent } from './page/usuarios/user-add/user-add.component';
import { FormatosCarguesComponent } from './page/formatos/formatos-cargues/formatos-cargues.component';
import { CarguePublicarComponent } from './page/formatos/cargue-publicar/cargue-publicar.component';
import { FormatosModalComponent } from './dashboard/home/formatos/formatos.component';
import { FormatosEditComponent } from './page/formatos/formatos-edit/formatos-edit.component';
import { JobsEditComponent } from './dashboard/jobs-edit/jobs-edit.component';
import { TalleresComponent } from './page/talleres/talleres.component';
import { TalleresAddComponent } from './page/talleres/talleres-add/talleres-add.component';
import { HomologacionDepartamentosComponent } from './page/homologaciones/departamentos/homologacionDepartamentos.component';
import { HomologacionMunicipiosComponent } from './page/homologaciones/municpios/homologacionMunicipios.component';
import {MinistroEditComponent} from './dashboard/ministro-edit/ministroEdit.component';
import { ProcesoCargueComponent } from './page/formatos/proceso-cargue/proceso-cargue.component';
/*
import { LockComponent } from './page/lock/lock.component';
import { RegisterComponent } from './page/register/register.component';*/

const routes: Routes = [

  // {path: 'lock', component: LockComponent},
  // {path: 'register', component: RegisterComponent},
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  { path: 'auth/login', component: LoginComponent },
  {
    path: '',
    canActivate: [AuthenticationGuard],

    component: RootComponent, children: [
      { path: 'home', component: HomeComponent },
      { path: 'noticias', component: NoticiasComponent },
      { path: 'talleres', component: TalleresComponent },
      { path: 'talleres/add', component: TalleresAddComponent },
      { path: 'noticias/add',component: NoticiaAddComponent},
      { path: 'preguntas', component: PreguntasComponent },
      { path: 'preguntas/add',component: PreguntaAddComponent},
      { path: 'formatos', component: FormatosComponent },
      { path: 'formatosModal', component: FormatosModalComponent },
      { path: 'formatos/add',component: FormatosAddComponent},
      { path: 'formatos/edit',component: FormatosEditComponent},
      { path: 'jobs/edit',component: JobsEditComponent},
      { path: 'formatos/cargues',component: FormatosCarguesComponent},
      { path: 'cargues/publicar',component: CarguePublicarComponent},
      { path: 'partidos', component: PartidosComponent },
      { path: 'partidos/add',component: PartidoAddComponent},
      { path: 'usuarios', component: UsuariosComponent },
      { path: 'usuarios/add', component: UserAddComponent },
      { path: 'roles/add',component: RoleAddComponent},
      { path: 'homologacion/departamentos',component: HomologacionDepartamentosComponent},
      { path: 'homologacion/municipios',component: HomologacionMunicipiosComponent},
      { path: 'components/price-table', component: PriceTableComponent },
      { path: 'penitenciario', component: EpenitenciarioComponent },
      { path: 'penitenciario/add',component: PenitenciarioAddComponent},
      { path: 'ministro/edit',component: MinistroEditComponent},
      { path: 'procesoCargue',component: ProcesoCargueComponent},
    ]
  },

];

export const routing = RouterModule.forRoot(routes);

